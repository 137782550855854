import React, { useContext, useEffect, useState } from "react";
// import { useAuth, useFirebaseApp } from "reactfire"; // SEEMS TO CAUSE TYPE ERRORS.
import auth, { getAuth, User, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updateEmail as authUpdateEmail, updatePassword as authUpdatePassword, deleteUser as authDeleteUser} from 'firebase/auth';
import { firebaseApp, functions } from "../App";
import { httpsCallable } from "firebase/functions";

export const AuthContext = React.createContext({currentUser:null as User | null | undefined, signup:(email:string, password:string)=>{return null as Promise<auth.UserCredential>|null}, login:(email:string, password:string)=>{return null as Promise<auth.UserCredential>|null}, logout:()=>{return null as Promise<void>|null}, resetPassword:(email:string)=>{return null as Promise<void>|null}, updateEmail:(email:string)=>{return null as Promise<void>|null}, updatePassword:(password:string)=>{return null as Promise<void>|null}, deleteAccount:()=>{return null as Promise<void>|null}}); //TODO: Could swap out signup and login for 'any'.

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC = ({children, ...props}) => {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);
  const auth = getAuth(firebaseApp);

  function signup(email:string, password:string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email:string, password:string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout(){
    localStorage.clear();
    if (currentUser) {
      return auth.signOut();
    } else {
      const nonSignOut = async ()=>{}
      return nonSignOut();
    }
  }

  function resetPassword(email:string) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email:string) {
    if (!!currentUser){
      return authUpdateEmail(currentUser, email);
    }
    return null //Or null is to resolve type issues.
  }

  function updatePassword(password:string) {
    if (!!currentUser){
      return authUpdatePassword(currentUser, password);
    }
    return null //Or null is to resolve type issues.
  }

  function deleteAccount() {
    if (!!currentUser) {
      return authDeleteUser(currentUser);
    }
    return null;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      setCurrentUser(user);
      setLoading(false); // Enables waits to render until current user is loaded.
    })

    return unsubscribe;
  }, [auth])


  return ( 
    <AuthContext.Provider value={{currentUser, signup, login, logout, resetPassword, updateEmail, updatePassword, deleteAccount}}>
      {!loading? children: <>Loading...</>}
    </AuthContext.Provider>
   );
}

export default AuthProvider;