import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonRadio, IonRadioGroup, IonText } from "@ionic/react";
import { useState } from "react";
import SynchDisabledButton from "./SynchDisabledButton";

export interface ForcedChoiceQuestion {
  prompt: string;
  orderedChoices: string[];
};

const PresentForcedChoiceQuestion:React.FC<{question:ForcedChoiceQuestion, answerCallback:(answerIndex:number)=>void}> = ({question, answerCallback, ...props})=>{
  const [selected, setSelected] = useState<number>(); // This has to be manually reset whenever the question is submitted for some reason.

  return <div style={{border:"1px solid var(--ion-color-primary)", borderRadius:"1em", padding:"1em"}}>
    <p>{question.prompt}</p>
    <IonText color="medium">(If you're not sure, just take your best guess.)</IonText>
    <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}>
      {question.orderedChoices.map((choice, index)=>{
        return <IonItem key={index}>
          <IonLabel text-wrap>{choice}</IonLabel>
          <IonRadio slot="start" value={index} />
        </IonItem>
      })}
    </IonRadioGroup>
    {selected || selected===0? <SynchDisabledButton onClick={()=>{answerCallback(selected);setSelected(undefined);}}>Submit Answer</SynchDisabledButton>:<></>}
  </div>
}

export default PresentForcedChoiceQuestion;