import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonRouterLink, IonRow, IonText } from "@ionic/react";
import { gradeFraming, gradeKnots, gradeLoads } from "common";
import React, { useState } from "react";
import DistributionEquipmentSimulator, { position } from "../pages/DistributionEquipmentSimulator";
import DistributionRopes from "../pages/DistributionRopes";
import EquipmentLoads from "../pages/EquipmentLoads";
import FramingHoles from "../pages/FramingHoles";
import TopTieSimulator from "../pages/TopTieSimulator";
import TransformerKnots from "../pages/TransformerKnots";
import { useData } from "../services/DataProvider";

const DistributionDemo: React.FC = () => {
  const {profile, setProperty} = useData();

  let currentComponent = <IonCard>
    <IonCardHeader><IonCardTitle>Welcome to the Distribution Lineman Demo!</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>You are about take a short assessment designed to measure medium voltage (residential distribution) lineman skills. Afterward, you will be presented with a sample debrief screen as would be seen by a training manager.</p>
      <IonButton onClick={()=>{setProperty("introScreen", {shown: true})}}>Continue</IonButton>
    </IonCardContent>
  </IonCard>

  const simulators = {
    "equipment": <DistributionEquipmentSimulator />,
    "framing": <FramingHoles />,
    "topTie": <TopTieSimulator />,
    "knots": <TransformerKnots />,
    "ropes": <DistributionRopes />,
    "loads": <EquipmentLoads />,
  };

  const setGradingComponent = ()=>{
    // Grade equipment selection?
    const gradeLabels = (labels:[string, boolean][]) => {
      const total = labels.length;
      let count = 0;
      labels.forEach(([label, targetValue]) => {
        const matches = ((profile?.["equipment"]?.["state"]?.["instances"] || []) as {name: string, position: position, active:boolean, mouseover:boolean, weight: number, caption?:string}[]).filter((item)=>{return item.name === label});
        if (matches.length >= 1) {
          count += (matches[0].active === targetValue? 1 : 0);
        } else {
          count += 1;
        }
      });
      return count / total;
    }

    let correct132 = 0;
    let tested132 = 0
    const steps = profile?.["topTie"].state || [] as string[];
    let firstStep = "top";
    if (["top", "bottom"].includes(steps[0])) {
      firstStep = steps[0];
      correct132 += 1;
    }
    tested132 += 1;
    // TODO: Finish creating softer grading criteria

    const targetAnswer = [
      "top",
      "button",
      "bottom",
      "button",
      "button",
      "button",
      "top",
      "button",
      "button",
      "fan out",
      "flatten"
    ];
    let matches132 = true;
    if (targetAnswer.length !== steps.length) {
      matches132 = false;
    } else {
      matches132 = targetAnswer.every((element, index)=>{
        return (steps[index] === element);
      })
    }

    const weldError = profile?.["topTie"].state?.includes("weld");

    const safeRopes = profile?.["ropes"].state?.handAboveWorker && profile?.["ropes"].state?.tagCorrectSide;
    const usefulRopes = profile?.["ropes"].state?.handCorrectSide && profile?.["ropes"].state?.tagOnTransformer && profile?.["ropes"].state?.pulleyAboveWorker && profile?.["ropes"].state?.pulleyOnTransformer;

    // NOTE: Copper Wire ungraded.

    const knotsGrades = gradeKnots(profile);
    const framingGrades = gradeFraming(profile);
    const loadsGrades = gradeLoads(profile);

    currentComponent = <IonCard>
      <IonCardHeader><IonCardTitle>Post-Simulator Debrief</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <p>This is what a training manager would see after an employee or job candidate completed the simulated tasks. Please note that all tasks and insights can be customized to your needs.</p>
        <br />
        <h1>Competence</h1>
        <h3>Necessary Tools: {(gradeLabels([["hammer", true],["skinning knife", true],["demolition screwdriver", true],["cutting pliers", true],["pump pliers", true],["electrical tape", true]])*100).toFixed(0)}%</h3>
        <h3>No Improper Equipment: {(gradeLabels([["multimeter", false],["inductor", false]])*100).toFixed(0)}%</h3>
        {matches132? <h3><IonText color="success">Flawless</IonText> 1-3-2 Top Tie</h3>: <h3><IonText color="warning">Mistakes</IonText> in 1-3-2 Top Tie</h3>}
        {usefulRopes && safeRopes? <h3><IonText color="success">Plausible</IonText> Rope Placement</h3> : <h3><IonText color="danger">Incorrect</IonText> Rope Placement</h3>}
        {framingGrades.technicalAvailable===framingGrades.technicalEarned? <h3><IonText color="success">Excellent</IonText> Framing Placement</h3> : <h3><IonText color="danger">Imperfect</IonText> Framing Holes</h3>}
        {knotsGrades.technicalAvailable===knotsGrades.technicalEarned? <h3><IonText color="success">Excellent</IonText> Rigging</h3> : <h3><IonText color="danger">Imperfect</IonText> Knot Selection</h3>}
        {loadsGrades.technicalAvailable===loadsGrades.technicalEarned? <h3><IonText color="success">Efficient</IonText> Lifting Equipment Selection</h3> : <h3><IonText color="danger">Imperfect</IonText> Load Estimation</h3>}
        <br />

        <h1>Safety</h1>
        <h3>Personal Protective Equipment: {(gradeLabels([["hardhat", true],["gloves", true]])*100).toFixed(0)}%</h3>
        <h3>No Unsafe Equipment: {(gradeLabels([["umbrella", false],["vise grip", false],["steel screwdriver", false]])*100).toFixed(0)}%</h3>
        {weldError? <h3>Unsafe Behaviors: <IonText color="danger">tried to weld a hot line</IonText></h3> : <></>}
        {safeRopes? <h3><IonText color="success">Safe</IonText> Rope Placement</h3> : <h3><IonText color="danger">Unsafe</IonText> Rope Placement</h3>}
        {loadsGrades.safetyAvailable===loadsGrades.safetyEarned? <h3><IonText color="success">Safe</IonText> Load Estimation</h3> : <h3><IonText color="danger">Unsafe</IonText> Load Ratings</h3>}
        <br />

        <br />
        <IonButton onClick={()=>{setProperty("reviewScreen", {shown: true});}}>Continue</IonButton>
      </IonCardContent>
    </IonCard>
  } // End setGradingComponent

  if (profile?.["introScreen"]?.["shown"]) {
    let simName: keyof typeof simulators;
    let simulatorAssigned = false;
    for (simName in simulators) {
      if (!profile?.[simName]?.["complete"]) {
        currentComponent = simulators[simName];
        simulatorAssigned = true;
        break;
      }
    }
    if (!simulatorAssigned) {
      setGradingComponent();
    }
  }

  if (profile?.["reviewScreen"]?.["shown"]) {
    const names = ["Sam", "Steven", "John Paul", "Robert", "Louis", "Derrick", "Hubert", "Jacob"];
    const roleGrades = names.map((name)=>{
      const technical = Math.sqrt(Math.random());
      const safety = Math.sqrt(Math.random());
      const team = Math.sqrt(Math.random());
      const match = (2*technical + safety + team)/4;


      return {
        name: name,
        technical,
        safety,
        team,
        match
      }
    }).sort((grade1, grade2)=>{
      return grade2.match-grade1.match;
    }); // Descending Sort

    // Show HR Ranking Page
    currentComponent = <>
      <IonCard style={{maxWidth: "initial", overflowX: "auto"}}>
        <IonCardHeader><IonCardTitle>Manager Dashboard</IonCardTitle></IonCardHeader>
        <IonCardContent>
          <h1 style={{textAlign:"center"}}>Select Role</h1>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow>
            {["Distribution Linemen", "Transmission Linemen", "Substation Electicians"].map((role, index)=>{
              return <IonCol key={index}><IonButton color={role==="Distribution Linemen"? "primary" : "secondary"} disabled={role!=="Distribution Linemen"}>{role}</IonButton></IonCol>
            })}
          </IonRow></IonGrid>

          {/* {roleRecords?.[0]? JSON.stringify(roleRecords[0]): <></>} */}
          <table style={{margin:"auto"}}>
          <thead>
            <tr>
              <td><IonCheckbox disabled={true}/></td>
              <td>ID {/*(click to view profile)*/}</td>
              <td>Overall Score</td>
              <td>Technical Knowledge</td>
              <td>Safety Compliance</td>
              <td>Team Work</td>
              <td>Contact</td>
            </tr>
          </thead>
          <tbody>
            {/* <IonText color="success">✓</IonText> */}
            {roleGrades.map((record, index)=>{
              return <tr key={index}>
                <td><IonCheckbox /></td>
                <td><IonRouterLink title='Set up billing to access detailed profiles.'>{record.name}</IonRouterLink></td>
                <td><IonText color={record.match > .70? "success" : "warning"}>{(record.match * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.technical * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.safety * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.team * 100).toFixed(0)}%</IonText></td>
                <td><IonButton color="primary" title='Upgrade to a premium account to access candidate contact information.' disabled={true}>Contact</IonButton></td>
              </tr>
            })}
          </tbody>
          </table>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow><IonCol><IonButton color="secondary" title='Upgrade to a premium account to access candidate contact information.' disabled={true}>Download CSV</IonButton></IonCol></IonRow></IonGrid>

        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow><IonCol>
            <IonButton onClick={()=>{Object.keys(profile).forEach((propertyName)=>{setProperty(propertyName, null)})}}>Demo: Return to the beginning</IonButton>
          </IonCol></IonRow></IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  }

  // TODO: Remove
  // currentComponent = <EquipmentLoads />

  return (<>
    {currentComponent}
  </>);
};

export default DistributionDemo;