import { IonButton, IonCard, IonCardContent, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import { addCircle, closeCircle } from "ionicons/icons";
import { useState } from "react";
import { useData } from "../services/DataProvider";
import TextListInput, { filterEmpties } from "./TextListInput";

const AdminCreateQuestion: React.FC = () => {
  const [questionType, setQuestionType] = useState("MCQ");
  const {roleToSkillNames, skillNamesToQuestionIDs, questionIDsToQuestions, skillIDsToSkills, synching, serverAdminCreateQuestion} = useData();
  const [selectedSkills, setSelectedSkills] = useState(Object.fromEntries(Object.keys(skillIDsToSkills).map((skillID)=>{
    return [skillID, false];
  })));
  const filteredSkills = Object.keys(selectedSkills).filter((skillID)=>{return selectedSkills[skillID]});
  const [prompt, setPrompt] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [unfilteredMultiCorrectAnswers, setMultiCorrectAnswers] = useState([] as string[]);
  const multiCorrectAnswers = filterEmpties(unfilteredMultiCorrectAnswers);
  const [unfilteredDistractors, setDistractors] = useState([] as string[]);
  const distractors = filterEmpties(unfilteredDistractors);

  const resetFields = () => {
    setPrompt("");
    setCorrectAnswer("");
    setMultiCorrectAnswers([]);
    setDistractors([]);
  }

  return <>
    <IonCard>
      <IonCardContent>
        <IonItem>
          <IonLabel>Question Type</IonLabel>
          <IonSelect value={questionType} onIonChange={(event)=>{setQuestionType(event.target.value);}}>
            <IonSelectOption>MCQ</IonSelectOption>
            <IonSelectOption>scramble</IonSelectOption>
            <IonSelectOption>selectAll</IonSelectOption>
          </IonSelect>
        </IonItem>

        {Object.keys(skillIDsToSkills).map((skillID, index)=>{
          return <IonItem key={index}>
            <IonCheckbox slot="start" checked={selectedSkills[skillID]} onClick={(event)=>{setSelectedSkills({...selectedSkills, [skillID]: event.currentTarget.checked});}}></IonCheckbox>
            <IonLabel text-wrap>{skillIDsToSkills[skillID].name}</IonLabel>
          </IonItem>
        })}

        <IonItem>
          <IonLabel position="floating">Prompt</IonLabel>
          <IonInput type="text" placeholder="My Name" value={prompt} onInput={(event)=>{setPrompt("" + event.currentTarget.value);}}/>
        </IonItem>

        {questionType==="MCQ"?
          <>
            <IonItem>
              <IonLabel position="floating">Correct Answer</IonLabel>
              <IonInput type="text" placeholder="My Name" value={correctAnswer} onInput={(event)=>{setCorrectAnswer("" + event.currentTarget.value);}}/>
            </IonItem>
            <h2>Distractors:</h2>
            <TextListInput defaultValues={unfilteredDistractors} onUpdate={(newDistractors)=>{setDistractors([...newDistractors]);}} />
            <IonButton onClick={()=>{
              serverAdminCreateQuestion({record:{
                qualityIDs: filteredSkills,
                question: {
                  QUESTION_TYPE: "MCQ",
                  prompt: prompt,
                  correctAnswer: correctAnswer,
                  distractors: distractors,
                }
              }});
              resetFields();
            }}>Create Question</IonButton>
          </>
        : questionType === "scramble"?
          <>
            <h2>Correct Order:</h2>
            <TextListInput defaultValues={unfilteredMultiCorrectAnswers} onUpdate={(newAnswers)=>{setMultiCorrectAnswers([...newAnswers]);}} />
            <IonButton onClick={()=>{
              serverAdminCreateQuestion({record:{
                qualityIDs: filteredSkills,
                question: {
                  QUESTION_TYPE: "scramble",
                  prompt: prompt,
                  steps: multiCorrectAnswers,
                }
              }});
              resetFields();
            }}>Create Question</IonButton>
          </>
        : questionType === "selectAll"?
          <>
            <h2>Correct Answers:</h2>
            <TextListInput defaultValues={unfilteredMultiCorrectAnswers} onUpdate={(newAnswers)=>{setMultiCorrectAnswers([...newAnswers]);}} />
            <h2>Distractors:</h2>
            <TextListInput defaultValues={unfilteredDistractors} onUpdate={(newDistractors)=>{setDistractors([...newDistractors]);}} />
            <IonButton onClick={()=>{
              serverAdminCreateQuestion({record:{
                qualityIDs: filteredSkills,
                question: {
                  QUESTION_TYPE: "selectAll",
                  prompt: prompt,
                  correctAnswers: multiCorrectAnswers,
                  distractors: distractors,
                }
              }});
              resetFields();
            }}>Create Question</IonButton>
          </>
        : <></>}

      </IonCardContent>
    </IonCard>
    <IonCard>
      <IonCardContent>
        <h1>Roles + Skills:</h1>
        {synching && <IonSpinner />}
        {Object.keys(roleToSkillNames).map((role, index)=>{
          const skillNames = roleToSkillNames[role];
          return <div key={index} style={{border:"1px solid black"}}>
            <h1>{role}</h1>
            {skillNames.map((skillName, index)=>{
              const questions = skillNamesToQuestionIDs[skillName] || [];

              return <div key={index} style={{border: "1px solid black"}}> 
                <h2>{skillName}</h2>
                {questions.map((questionID, index)=>{
                  return <div key={index} style={{border: "1px solid black"}}>
                    {JSON.stringify(questionIDsToQuestions[questionID].question)}
                  </div>
                })}
              </div>

            })}
          </div>
        })}
      </IonCardContent>
    </IonCard>
  </>
}

export default AdminCreateQuestion;