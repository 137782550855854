import { IonAccordion, IonAccordionGroup, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRouterLink, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { calendar, construct, informationCircle, map, personCircle, thumbsUp, wallet, addCircle, closeCircle} from 'ionicons/icons';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Chart from 'react-apexcharts';
import ExploreContainer from '../components/ExploreContainer';
import { useData } from '../services/DataProvider';
import './Home.css';

const DemoHome: React.FC = () => {
  const {profile, setProperty, synching} = useData();

  const createUpdateFunction = (object: any, updater: ((...args: [any])=>void)) => {
    return (propertyName: string, value: any) => {
      object[propertyName] = value;
      updater({...object});
      return object;
    }
  }

  const hardSkillsMap = {
    "electrical troubleshooting": "TODO", 
    "high-voltage safety": "TODO", 
    "mechanical troubleshooting": "TODO", 
    "lockout/tagout": "TODO", 
    "wind turbine maintenance": "TODO", 
    "solar installation": "TODO", 
    "electrical splicing": "TODO"
  }
  const hardSkills = Object.keys(hardSkillsMap) as (keyof typeof hardSkillsMap)[];
  const readinessMap = {
    "communication": "We use scenario-based writing samples to identify role-specific communication competencies and styles.",
    "willingness to travel": "We use highly crafted language to filter applicants who are genuinely willing to spend several nights a week away from home at remote in-state locations.",
    "comfort with outdoor work": "We screen for characteristics of outdoor-content candidates, such as a history of outdoor leisure activities.",
    "work ethic": "We silently measure consistent progress, deliberate focus, and perseverance in our simulator-based tasks.",
    "team cooperation": "Through interactive dialogues, we detect confrontation styles, de-escalation skills, and pro-social behaviors."
  }
  const readinessQualities = Object.keys(readinessMap) as (keyof typeof readinessMap)[];

  const [selectedHardSkills, updateHardSkills] = useState({} as {[key:string]: boolean});
  const setHardSkill = createUpdateFunction(selectedHardSkills, updateHardSkills);
  const neededHardSkills = hardSkills.filter((skill)=>{return selectedHardSkills[skill]});
  const otherHardSkills = hardSkills.filter((skill)=>{return ! selectedHardSkills[skill]});

  const [selectedReadinessQualities, updateReadinessQualities] = useState({} as {[key:string]: boolean});
  const setReadiness = createUpdateFunction(selectedReadinessQualities, updateReadinessQualities);
  const neededReadinessQualities = readinessQualities.filter((quality)=>{return selectedReadinessQualities[quality]});
  const otherReadinessQualities = readinessQualities.filter((quality)=>{return ! selectedReadinessQualities[quality]});

  const [customHardSkills, setCustomHardSkills] = useState([] as string[]);
  const nonemptyCustomSkills = customHardSkills.filter((skill)=>{return skill.trim().length > 0});
  const totalSkills = neededHardSkills.length + nonemptyCustomSkills.length;

  const [activeAccordian, setActiveAccordian] = useState("skillsActive");

  //Parameters for ROI calculation:
  // Create our number formatter.
  var usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  function nFormatter(num:number, digits:number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "Bn" }, // "G" for scientific uses
      { value: 1e12, symbol: "Tr" }, // "T" for scientific uses
      { value: 1e15, symbol: "Qd" }, // "P" for scientific uses
      { value: 1e18, symbol: "Qn" }, // "E" for scientific uses
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? Number((num / item.value).toPrecision(digits)).toString().replace(rx, "$1") + " " + item.symbol : "0";
  }
  //...
  const exampleCompany = "Vestas";
  const currentMarketValue = 42 * 10**9; //Value of current installations by Vestas, estimated at $1MM / MW installation.
  const techniciansPerDollar = 3268 / 42*10**9; //Estimated as 3268 / 42*10**9 technicians per marketvalue
  const currentTechnicians = currentMarketValue * techniciansPerDollar;
  const termStart = 2022;
  const termEnd = 2030;
  const expectedGrowth = 5; // Growing 5 times, based on aggressive US energy policies. (70% renewables by 2030)
  const annualGrowth = Math.pow(expectedGrowth, 1/(termEnd-termStart));
  const years = Array(termEnd - termStart + 1).fill(0).map((value, index)=>{
    return termStart + index;
  });
  const potentialMarketValues = years.map((value, index)=>{
    return currentMarketValue * Math.pow(annualGrowth, index) - currentMarketValue;
  });
  const attainableMarketValues = years.map((value, index)=>{
    return index * ( .1727*currentTechnicians / techniciansPerDollar); // Assumes Vestas is able to expand their capacity by 17% of their 2022 size each year. (currentMarketValue + in order to get lifetime total.)
  });
  const valueDifferences = years.map((value, index)=>{
    return potentialMarketValues[index] - attainableMarketValues[index];
  });


  const exampleCost = valueDifferences[valueDifferences.length - 1];//valueDifferences.reduce((sum, current) => sum + current, 0);
  const exampleRealizedValue = attainableMarketValues[attainableMarketValues.length - 1];
  const exampleFinalPotential = potentialMarketValues[potentialMarketValues.length - 1];
  const lostFraction = 1 - exampleRealizedValue/exampleFinalPotential;

  const revChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      stacked: false,
      width: "100%",
      zoom: {
        enabled: false
      },
    },
    colors: ["#000000", "#6aa84f", ],//["#6aa84f", "#910f00", "#92949c"], // Unfortunately, these will have to be manuallly updated if the colorscheme changes.
    // grid: {
    //   padding: {
    //     left: 20,
    //     right: 40 // Also you may want to increase this (based on the length of your labels)
    //   },
    // },
    title: {
      // @ts-ignore: Type definitions only allow for a string, but the code actually supports a list of strings.
      // https://apexcharts.com/docs/options/title/ vs. https://apexcharts.com/docs/multiline-text-and-line-breaks-in-axes-labels/ and https://github.com/apexcharts/apexcharts.js/issues/388
      text: ['(Example) Market Potential vs.','Staffable Installation Revenue: Cumulative'],
      align: 'center',
    },
    stroke: {
      curve: 'straight' // "smooth produces weird 0-slope artifacts"
    },
    dataLabels: {
      enabled: false // Turns off unformatted numbers spaced over the curves.
    },
    labels: years.map((value)=>{return value.toString()}), //Replaces xaxis commands?
    yaxis: {
      opposite:true,
      labels: {
        formatter: (val, opts) => {
          return "$" + nFormatter(val, 2);
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };
  const revChartSeries = [
    {
      name: "Market Potential Revenue",
      data: potentialMarketValues,
    },
    {
      name: "Staffable Installation Revenue",
      data: attainableMarketValues
    },
  ];


  const [unfilledRoles, setUnfilledRoles] = useState(10);
  const [applicantsPerRole, setApplicantsPerRole] = useState(20); // TODO: Use?
  const [deiChecked, setDEIChecked] = useState(true);
  const [averageSalary, setAverageSalary] = useState(60000);
  const [averageWeeksToFill, setAverageWeeksToFill] = useState(6);
  const [weeksOfTraining, setWeeksOfTraining] = useState(13);
  const [reductionInTrainingTime, setReductionInTrainingTime] = useState(.10); // Fraction
  const [currentTurnover, setCurrentTurnover] = useState(.15); // 1-year turnover (fraction)
  const [reductionInTurnover, setReductionInTurnover] = useState(.5); // Fraction
  const [increasedProductivity, setIncreasedProductivity] = useState(.02); //Fraction

  // Compute ROI in terms of cost reduction.
  // TODO: Separately estimate impact on revenue?
  const setupCost = (totalSkills + neededReadinessQualities.length) * 5000 + 50000;
  const operatingCosts = years.map(()=>{return 1000 * unfilledRoles}); // TODO: Update.
  const annualCosts = years.map((value, index)=>{return operatingCosts[index]});
  annualCosts[0] += setupCost;
  let costSoFar = setupCost;
  const cumulativeCosts = years.map((value, index)=>{
    costSoFar += operatingCosts[index];
    return costSoFar;
  });
  const estimatedCostToFill = (averageWeeksToFill + weeksOfTraining)/52 * averageSalary * 1.5; // Very rough estimate, loosely estimates recruiter time and lost productivity of senior personnel and training staff.
  const annualReturns = years.map((value, index)=>{
    return (estimatedCostToFill * currentTurnover * reductionInTurnover * unfilledRoles) + (index * increasedProductivity * unfilledRoles * averageSalary); // Estimated (roughly) from reduction in turnover and cumulative impact of increased productivity.
  });
  let returnsSoFar = 0;
  const cumulativeReturns = years.map((value, index) => {
    returnsSoFar += annualReturns[index];
    return returnsSoFar;
  });
  const cumulativeROI = years.map((value, index) => {
    return (cumulativeReturns[index]-cumulativeCosts[index]) / cumulativeCosts[index];
  });


  const roiChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      },
    },
    colors: ["#6aa84f", ],
    title: {
      text: "ROI (cumulative) over time",
      align: 'center',
    },
    stroke: {
      curve: 'straight' // "smooth produces weird 0-slope artifacts"
    },
    dataLabels: {
      enabled: false // Turns off unformatted numbers spaced over the curves.
    },
    labels: years.map((value)=>{return value.toString()}), //Replaces xaxis commands?
    yaxis: {
      min: (min)=>{return Math.min(min, 0)},
      max: (max)=>{return Math.max(max, 0)},
      opposite:true,
      labels: {
        formatter: (val, opts) => {
          return `${(val*100).toFixed(0)}%`;
        }
      },
      forceNiceScale:true,
    },
    annotations: {
      yaxis: [{
        y: 0,
        borderColor: "#FF0000",
      }]
    }
  };
  const roiChartSeries = [
    {
      name: "ROI (cumulative)",
      data: cumulativeROI,
    },
  ];

  const financialChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false
      },
    },
    colors: ["#000000", "#6aa84f", ],
    title: {
      text: "Costs and Savings",
      align: 'center',
    },
    stroke: {
      curve: 'straight' // "smooth produces weird 0-slope artifacts"
    },
    dataLabels: {
      enabled: false // Turns off unformatted numbers spaced over the curves.
    },
    labels: years.map((value)=>{return value.toString()}), //Replaces xaxis commands?
    yaxis: {
      opposite:true,
      labels: {
        formatter: (val, opts) => {
          return "$" + nFormatter(val, 2);
        }
      }
    },
  };
  const fincancialChartSeries = [
    {
      name: "PowerTechs cost",
      data: annualCosts,
    },
    {
      name: "Business Savings",
      data: annualReturns,
    },
  ];

  return (<IonPage>
    <IonContent style={{"--background":"url('/assets/splash/PowerTechsLightSlideBackground.png') no-repeat center center / cover"}}>
    <ion-tabs> {/* Switching to raw component resolves a bizarre compilation error...  https://github.com/ionic-team/ionic-framework/issues/19790 */}
      <IonTabBar slot="top">
        <IonTabButton tab="ROI" href='/roi'>
          <IonIcon icon={wallet} />
          <IonLabel>ROI</IonLabel>
        </IonTabButton>

        <IonTabButton tab="skills"  href='/skills'>
          <IonIcon icon={construct} />
          <IonLabel>Hard Skills and Safety</IonLabel>
          {totalSkills > 0 ? <IonBadge>{totalSkills}</IonBadge> : <></>}
        </IonTabButton>

        <IonTabButton tab="readiness" href='/readiness'>
          <IonIcon icon={thumbsUp} />
          <IonLabel>Work Readiness</IonLabel>
          {neededReadinessQualities.length > 0 ? <IonBadge>{neededReadinessQualities.length}</IonBadge> : <></>}
        </IonTabButton>

        {/* <IonTabButton tab="about">
          <IonIcon icon={informationCircle} />
          <IonLabel>About</IonLabel>
        </IonTabButton> */}
      </IonTabBar>

      <Switch>
        <Route exact path={["/skills"]}>
            <IonCard>
              <IonCardHeader><IonCardTitle>Hard Skills and Safety</IonCardTitle></IonCardHeader>
              <IonCardContent>
                <p>Our simulator-based adaptive skills assessments are able to measure practical ability and aptitudes.</p>
                <br />
                {neededHardSkills.map((skillName, index)=>{
                  return <div key={index}>
                    <h1 key={"h1" + index}>{skillName}</h1>
                    {/* <p key={"p" + index}>{hardSkillsMap[skillName]}</p> */}
                    <br />
                  </div>
                })}
                {nonemptyCustomSkills.length > 0?
                  <>
                    <h1>custom hard skills</h1>
                    <p>We work closely with our clients to match our assessments to your skills requirements. For example, you listed:</p>
                    <ul>
                      {nonemptyCustomSkills.map((value, index)=>{
                        return <li key={index}>{value}</li>
                      })}
                    </ul>
                    <br />
                  </>
                : <></>}
                {otherHardSkills.length > 0? 
                <IonAccordionGroup>
                  <IonAccordion value="otherReadiness">
                    <IonItem slot="header"  color="light"><IonLabel>{neededHardSkills.length > 0? "Additional Skills": "Hard Skills"}</IonLabel></IonItem>
                    <div slot="content" className='ion-padding' style={{border: "1px solid var(--ion-color-tertiary)", paddingBottom: "0"}}>
                    {otherHardSkills.map((skillName, index)=>{
                      return <div key={index}>
                        <h1 key={"h1" + index}>{skillName}</h1>
                        {/* <p key={"p" + index}>{hardSkillsMap[skillName]}</p> */}
                        <br />
                      </div>
                    })}
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
                : <></>}
              </IonCardContent>
            </IonCard>
        </Route>

        <Route exact path={["/readiness"]}>
            <IonCard>
              <IonCardHeader><IonCardTitle>Work Readiness</IonCardTitle></IonCardHeader>
              <IonCardContent>
                <p>In addition to hard skills, we screen for non-technical work readiness qualities that affect the employability of a candidate.</p>
                <br />
                {neededReadinessQualities.map((qualityName, index)=>{
                  return <div key={index}>
                    <h1 key={"h1" + index}>{qualityName}</h1>
                    <p key={"p" + index}>{readinessMap[qualityName]}</p>
                    <br />
                  </div>
                })}
                {otherReadinessQualities.length > 0? 
                <IonAccordionGroup>
                  <IonAccordion value="otherReadiness">
                    <IonItem slot="header"  color="light"><IonLabel>{neededReadinessQualities.length > 0? "Additional Qualities": "Work Readiness Qualities"}</IonLabel></IonItem>
                    <div slot="content" className='ion-padding' style={{border: "1px solid var(--ion-color-tertiary)", paddingBottom: "0"}}>
                    {otherReadinessQualities.map((qualityName, index)=>{
                      return <div key={index}>
                        <h1 key={"h1" + index}>{qualityName}</h1>
                        <p key={"p" + index}>{readinessMap[qualityName]}</p>
                        <br />
                      </div>
                    })}
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
                : <></>}

              </IonCardContent>
            </IonCard>
        </Route>

        <Route exact path={["/roi"]}>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Return on Investment Calculator</IonCardTitle>
                <IonCardSubtitle>Fill the Skills Gap</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <p>Unfilled and underskilled positions could cost you <IonText color="danger">{Math.round(lostFraction * 100)}%</IonText> of your potential revenue over the next {termEnd - termStart} years.</p>
                <Chart type="area" options={revChartOptions} series={revChartSeries} width={"100%"} />
                <p>Scroll down to see how PowerTechs can increase your revenue while decreasing costs.</p>
                <br />

                <IonAccordionGroup value={activeAccordian} onIonChange={(event)=>{
                  if(event.target.tagName==="ION-ACCORDION-GROUP" && event.detail.value !== activeAccordian && event.detail.value !== "on") {setActiveAccordian("" + event.detail.value)}
                }}>
                  <IonAccordion value="skillsActive">
                    <IonItem slot="header"  color="light"><IonLabel>Needed Skills?</IonLabel></IonItem>
                    <div slot="content" className='ion-padding' style={{border: "1px solid var(--ion-color-tertiary)"}}>
                      <p>Across all of the roles your company is trying to fill, what are some of the skills that are important for you to find or develop? (Check all that apply, or enter your own.)</p>
                      {hardSkills.map((skillName, index)=>{
                        return <IonItem key={index}>
                          <IonCheckbox slot="start" checked={selectedHardSkills[skillName]} onClick={(event)=>{setHardSkill(skillName, event.currentTarget.checked);}}></IonCheckbox>
                          <IonLabel text-wrap>{skillName}</IonLabel>
                        </IonItem>
                      })}
                      {customHardSkills.map((skill, index)=>{
                        return <IonItem key={index}>
                          <IonInput value={skill} placeholder="enter skill name" onInput={(event)=>{customHardSkills[index]=""+event.currentTarget.value;setCustomHardSkills([...customHardSkills]);}}/>
                          <IonIcon slot="end" icon={closeCircle} color="danger" onClick={()=>{customHardSkills.splice(index, 1); setCustomHardSkills([...customHardSkills]);}}/>
                        </IonItem>
                      })}
                      <IonItem onClick={()=>{setCustomHardSkills([...customHardSkills, ""]);}}>
                        <IonIcon icon={addCircle} slot="start" color="primary"/>
                        <IonLabel>(Add an Item)</IonLabel>
                      </IonItem>
                      <br />
                      <p>What other employability factors are you looking for?</p>
                      {readinessQualities.map((qualityName, index)=>{
                        return <IonItem key={index}>
                          <IonCheckbox slot="start" checked={selectedReadinessQualities[qualityName]} onClick={(event)=>{setReadiness(qualityName, event.currentTarget.checked);}}></IonCheckbox>
                          <IonLabel text-wrap>{qualityName}</IonLabel>
                        </IonItem>
                      })}
                    </div>
                  </IonAccordion>
                {/* </IonAccordionGroup>
                <IonAccordionGroup> */}
                  <IonAccordion value="hiringActive">
                    <IonItem slot="header"  color="light"><IonLabel>Hiring Process?</IonLabel></IonItem>
                    <div slot="content" className='ion-padding' style={{border: "1px solid var(--ion-color-tertiary)"}}>
                      <p>Across your organization, how many positions did you fill last year (internally or externally)?</p>
                      <IonItem>
                        <IonLabel position="floating"># Positions Filled</IonLabel>
                        <IonInput value={unfilledRoles} type="number" min="0" onInput={(event)=>{setUnfilledRoles(parseInt("" + event.currentTarget.value))}}/>
                      </IonItem>
                      <br />
                      <p>Do you track recruiting diversity metrics?</p>
                      <IonItem>
                        <IonCheckbox slot="start" checked={deiChecked} onClick={(event)=>{setDEIChecked(event.currentTarget.checked)}}></IonCheckbox>
                        <IonLabel text-wrap>We track DEI metrics.</IonLabel>
                      </IonItem>
                      <br />
                      <p>What's the average salary for these positions?</p>
                      <IonItem>
                        <IonLabel position="floating">Average Salary</IonLabel>
                        <IonInput value={averageSalary} type="number" min="25000" onInput={(event)=>{setAverageSalary(parseInt("" + event.currentTarget.value))}}/>
                      </IonItem>
                      <br />
                      <p>How long (in weeks) does it usually take to fill these positions?</p>
                      <IonItem>
                        <IonLabel position="floating">Average Weeks to Fill</IonLabel>
                        <IonInput value={averageWeeksToFill} type="number" min="0" onInput={(event)=>{setAverageWeeksToFill(parseInt("" + event.currentTarget.value))}}/>
                      </IonItem>
                      <br />
                    </div>
                  </IonAccordion>
                {/* </IonAccordionGroup>
                <IonAccordionGroup> */}
                  <IonAccordion value="trainingActive">
                    <IonItem slot="header"  color="light"><IonLabel>Training Process?</IonLabel></IonItem>
                    <div slot="content" className='ion-padding' style={{border: "1px solid var(--ion-color-tertiary)"}}>
                      <p>How long is your typical safety and job training process?</p>
                      <IonItem>
                        <IonLabel position="floating">Training Duration (Weeks)</IonLabel>
                        <IonInput value={weeksOfTraining} type="number" min="1" onInput={(event)=>{setWeeksOfTraining(parseInt("" + event.currentTarget.value))}}/>
                      </IonItem>
                      <br />
                      <p>What percentage of workers turn over within 1 year of starting a new role?</p>
                      <IonItem>
                        <IonLabel position="floating">% turnover of new workers</IonLabel>
                        <IonInput value={currentTurnover*100} type="number" min="0" max="100" onInput={(event)=>{setCurrentTurnover(parseInt("" + event.currentTarget.value)/100)}}/>
                      </IonItem>
                      <br />
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
                <br />
                <h1>ROI Estimate</h1>
                <p>Annual savings come primarily from reduced turnover and a small increase in hired worker productivity. Costs include a setup fee and ongoing usage fees.</p>

                <Chart type="line" options={financialChartOptions} series={fincancialChartSeries} />
                <Chart type="line" options={roiChartOptions} series={roiChartSeries} />

                <IonText color="warning"><p>This is not a price quote. This tool is for estimation purposes only.</p></IonText>
              </IonCardContent>
            </IonCard>
        </Route>

        <Route>
          <Redirect to="/roi"/>
        </Route>
      </Switch>
    </ion-tabs>

    <div style={{textAlign:"center", margin:"1em"}}><IonButton color="success" href="https://docs.google.com/forms/d/e/1FAIpQLSfzGGWdu4IKvtfQttqwG_JtnD0DXVKPByOroaHQubaH2Nd2Dg/viewform">Schedule a Live Demo</IonButton></div>

    </IonContent>
    </IonPage>
  );
};

export default DemoHome;