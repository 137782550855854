import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
// import { useHistory } from "react-router";
import { useAuth } from "../services/AuthProvider";
import useSubdomain from "../services/SubdomainMapper";

const LogIn: React.FC = ({...props}) => {
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {login} = useAuth();

  const subdomain = useSubdomain();

  // const history = useHistory();

  let tagline = "Your job in renewables is waiting.";
  if (subdomain === "hr") {
    tagline = "Skilled workers are waiting.";
  } else if (subdomain === "linemen") {
    tagline = "Compete for cash prizes and see where your skills rank!";
  }

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    setError('');
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    await (login("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
      // setLoading(false);
      // history.push("/"); //Redirect to home page/dashboard after login.
      // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    }).catch((reason)=>{
      setError('Failed to log in: ' + (reason.message || ""));
      setLoading(false);
      //console.log(reason); //TODO: Present to the user.
    }));
  }

  //TODO: Set color of error based on ionic colors
  return <IonCard>
    {/* <img src="/assets/logos/PowerTechsLogoColor.svg" style={{margin:"1em", height:"4em", float:"right"}}/> */}
    <IonCardHeader>
      <IonCardTitle><img src="/assets/logos/PowerTechsIcon.svg" style={{height:"1.2em", position:"relative", top:".23em"}}/>PowerTechs Log In</IonCardTitle>
      <IonText color="dark"><h5>{tagline}</h5></IonText>
    </IonCardHeader>
    
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput type="email" ref={emailRef} placeholder="example: first.last@gmail.com"/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Password</IonLabel>
        <IonInput type="password" ref={passwordRef}/>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Log In
      </IonButton>
      <p><IonRouterLink routerLink="/forgot-password">Forgot Password?</IonRouterLink></p>
      </form>
      <IonText color="medium"><p>Need an account? <IonRouterLink routerLink="/signup">Sign Up</IonRouterLink></p></IonText>
    </IonCardContent>
  </IonCard>
};

export default LogIn;

