import { IonButton, IonCard, IonCardContent, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRouterLink, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { profile } from 'console';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import { useData } from '../services/DataProvider';
import './Home.css';

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
// https://stackoverflow.com/questions/42136098/array-groupby-in-typescript

const nameCase = (name: string) => {
  if (!name) {
    return "";
  }
  const parts = name.split(" ");
  return parts.map((part)=>{
    return part? part[0].toUpperCase() + part.slice(1).toLowerCase() : part;
  }).join(" ")
}

const HRHome: React.FC = () => {
  const [hrRecords, setHRRecords] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  const {serverFetchHRRecords} = useData();
  let [selectedRole, setSelectedRole] = useState("");

  useEffect(()=>{
    setLoading(true);

    serverFetchHRRecords({collectionName: "profiles"}).then((result)=>{
      setHRRecords(result.data);
      setLoading(false);
    })

  }, []); // Only run once.

  const roleGroups = groupBy(hrRecords, (record)=>{
    return record?.["role"];
  }) || {}

  const roles = Object.keys(roleGroups).filter((roleName)=>{return roleName && roleName!=="undefined"}); // Not sure how "undefined" gets introduced into the records.
  // Sort by number of candidates.
  roles.sort((role1, role2)=>{return (roleGroups[role2] || []).length - (roleGroups[role1] || []).length}); // Decreasing
  if (!selectedRole && roles.length > 0) {
    selectedRole = roles[0];
  }

  const roleRecords = roleGroups[selectedRole] || [];

  // TODO: Grade roleRecords
  // const roleGrades = roleRecords.map((record)=>{
  //   let technicalAvailable = 0;
  //   let technicalEarned = 0;
  //   const questionnaireResponses = record["questionnaire"] || {};
  //   for (let questionName in questionnaireResponses) {
  //     if (questionName === "complete") {
  //       // Skip; just marking completion
  //     } else if (questionName === "ohmsLaw") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "V"? 1 : 0;
  //     } else if (questionName === "powerType") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "DC"? 1 : 0;
  //     } else if (questionName === "neutralGrounding") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "Unearthed Neutral System"? 1 : 0;
  //     } else if (questionName === "Nacelle") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "the nacelle"? 1 : 0;
  //     } else if (questionName === "capacitanceUnits") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "F"? 1 : 0;
  //     } else if (questionName === "multiphasePower") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "3"? 1 : 0;
  //     }
  //   }
  //   technicalAvailable ||= 1; // Set to 1 if still 0.
  //   let technicalScore = technicalEarned/technicalAvailable;

  //   let safetyAvailable = 0;
  //   let safetyEarned = 0;
  //   safetyEarned += safetyAvailable===0? 1 : 0;
  //   safetyAvailable ||= 1;
  //   let safetyScore = safetyEarned/safetyAvailable;

  //   const finalMatch = (technicalScore + safetyScore)/2;

  //   return {contactInfo:record.contactInfo, match: finalMatch, technicalKnowledge: technicalScore, safetyCompliance: safetyScore, teamWork: Math.random()};
  // }).sort((record1, record2)=>{return record2.match - record1.match}); // Sort in descending order.

  const roleGrades = roleRecords.map((record)=>{
    return record;
  }).sort((record1, record2)=>{
    if(!record1.fractionComplete || !record1.matchFractions["match"]) {
      return 1;
    } else if (!record2.fractionComplete || !record2.matchFractions["match"]) {
      return -1;
    }
    if(record1.fractionComplete !== record2.fractionComplete) { // Sort in decreasing order.
      return record2.fractionComplete - record1.fractionComplete;
    } else {
      return record2.matchFractions["match"] - record1.matchFractions["match"]
    }
  })

  const displayDigits = 2;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Power Techs - Hiring Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Power Techs - HR</IonTitle> {/* This is what shows on mobile. */}
          </IonToolbar>
        </IonHeader>

        <IonCard style={{maxWidth: "initial", overflowX: "auto"}}>
          <IonCardContent>
            {roles.length > 1? <>
              <h1 style={{textAlign:"center"}}>Select Role</h1>
              <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow>
                {roles.map((role, index)=>{
                  return <IonCol key={index}><IonButton color={role===selectedRole? "primary" : "secondary"} onClick={()=>{setSelectedRole(role)}}>{role}</IonButton></IonCol>
                })}
              </IonRow></IonGrid>
            </> 
            : <></>}
            {loading? <IonSpinner style={{display:"block", margin:"auto"}} /> : <></>}

            {/* {roleRecords?.[0]? JSON.stringify(roleRecords[0]): <></>} */}
            <table style={{margin:"auto"}}>
            <thead>
              <tr><td><IonCheckbox disabled={true}/></td><td>ID {/*(click to view profile)*/}</td><td>Match</td><td>Technical Knowledge</td><td>Safety Compliance</td><td>Team Work</td><td>Contact</td></tr>
              </thead>
              <tbody>
              {/* <IonText color="success">✓</IonText> */}
              {roleGrades.map((record, index)=>{
                const matchFraction = record?.matchFractions?.match? (record?.matchFractions?.match * 100).toFixed(displayDigits) : "~";
                const technicalFraction = record?.matchFractions?.technical? (record?.matchFractions?.technical * 100).toFixed(displayDigits) : "~";
                const safetyFraction = record?.matchFractions?.safety? (record?.matchFractions?.safety * 100).toFixed(displayDigits) : "~";
                const employabilityFraction = record.matchFractions?.employability? (record?.matchFractions?.employability * 100).toFixed(displayDigits) : "~";

                const secretData = (index < 10 && window.location.hostname.toLowerCase() == "localhost")? `Name: ${nameCase(record?.contactInfo?.["givenName"])} ${nameCase(record?.contactInfo?.["surname"])}\nPhone: ${record?.contactInfo?.["phone"]}\nEmail: ${(record.owner || record.id)}` : (record.owner || record.id);

                return <tr key={index}><td><IonCheckbox /></td><td><IonRouterLink title='Set up billing to access detailed profiles.'>{nameCase(record?.contactInfo?.["givenName"]) || "id not found"}</IonRouterLink></td><td><IonText color="success">{matchFraction}%</IonText></td><td><IonText>{technicalFraction}%</IonText></td><td><IonText>{safetyFraction}%</IonText></td><td>{employabilityFraction}%</td><td><IonButton color="primary" title={`Upgrade to a premium account to access candidate contact information: ${record.owner || record.id}`} disabled={true}onClick={()=>{navigator.clipboard.writeText(secretData);}}>Contact</IonButton></td></tr>
              })}
            </tbody>
            </table>
            <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow><IonCol><IonButton color="secondary" title='Upgrade to a premium account to access candidate contact information.' disabled={true}>Download CSV</IonButton></IonCol></IonRow></IonGrid>
            
          </IonCardContent>
        </IonCard>


        <IonCard>
          <IonCardContent>
            <h1>Have particular hiring or training needs?</h1>
            <p>
              Find employees with the skills you need.
            </p>
            <IonButton href='https://docs.google.com/forms/d/e/1FAIpQLSfzGGWdu4IKvtfQttqwG_JtnD0DXVKPByOroaHQubaH2Nd2Dg/viewform'>Request a Live Demo</IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default HRHome;