import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";

const CircuitTestingSimulator: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {width, height} = useWindowDimensions();

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  const multimeter = new Image();
  const diagram = new Image();
  const breakerOn = new Image();
  const breakerOff = new Image();
  const resistor = new Image();
  const toggleOn = new Image();
  const toggleOff = new Image();
  const redLead = new Image();
  const blackLead = new Image();

  let mousePositionX = 0;
  let mousePositionY = 0;
  let multimeterSetting = "Ω";
  const defaultReadout = "---";
  let multimeterReadout = defaultReadout;
  let faultyComponent = "";
  let toolSelected = "multimeter"; // or "resistor";
  let toggleIsOn = true;
  let breakerIsOn = true;

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth);
    mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight);
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:number[]) => {
    // console.log(`MouseX: ${mouseX}, MouseY: ${mouseY}`);
    // console.log(`Bounding Box: ${boundingBox}`)
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    mousePositionX = (event.clientX - canvas.getBoundingClientRect().left);
    mousePositionY = (event.clientY - canvas.getBoundingClientRect().top);

    if(checkInside(mousePositionX, mousePositionY, [10 - 5, 3* canvasHeight/20 -5, .2 * canvasWidth + 10, (multimeter.height * .2*canvasWidth/multimeter.width) + 10])) {
      toolSelected = "multimeter";
    }

    if(checkInside(mousePositionX, mousePositionY, [.8*canvasWidth - 5, 10 - 5, .15*canvasWidth + 10, (resistor.height * .15*canvasWidth/resistor.width) + 10])) {
      toolSelected = "resistor";
      multimeterReadout = defaultReadout;
    }

    // TODO: Replace with actual readings
    if(toolSelected === "multimeter" && checkInside(mousePositionX, mousePositionY, [.25*canvasWidth, 0, .5*canvasWidth, (diagram.height * .5*canvasWidth/diagram.width)])) {
      multimeterReadout = `0 ${multimeterSetting==="continuity"? "L" : multimeterSetting}`;
    }

    if(checkInside(mousePositionX, mousePositionY, [.8*canvasWidth, .2*canvasHeight, .05*canvasWidth, (toggleOn.height * .05*canvasWidth/toggleOn.width)])) {
      toggleIsOn = !toggleIsOn;
    }

    if(checkInside(mousePositionX, mousePositionY, [.9*canvasWidth, .2*canvasHeight, .05*canvasWidth, (breakerOn.height * .05*canvasWidth/breakerOn.width)])){
      breakerIsOn = !breakerIsOn;
    }
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // // Draw to fill canvas.
    // const windmillsHeight = context.canvas.height;
    // const windmillsWidth = windmills.width * windmillsHeight / windmills.height;

    // const virtualHeight = 500; // 500 meter height of the windmills image.

    // const personVirtualHeight = 20; // 20 meters tall (not to scale; just need to make person visible.)

    // const personHeight = context.canvas.height * personVirtualHeight / virtualHeight;
    // const personWidth = person.width * personHeight / person.height;

    //Our first draw
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    // context.drawImage(windmills, 0, 0, windmillsWidth, windmillsHeight);
    // // draw a circle behind the person.
    // if (personSelected) {
    //   context.fillRect(mousePosition - personWidth/2, canvas.height * 450/virtualHeight - personHeight/2, 2 * personWidth, 2 * personHeight);
    // }
    // context.drawImage(person, mousePosition, canvas.height * 450/virtualHeight, personWidth, personHeight);

    if (toolSelected === "multimeter") {
      context.fillStyle = "#aaaaaa";
      context.fillRect( 10 - 5, 3* canvasHeight/20 -5, .2 * canvasWidth + 10, (multimeter.height * .2*canvasWidth/multimeter.width) + 10);
    }

    context.drawImage(multimeter, 10, 3* canvasHeight/20, .2 * canvasWidth, (multimeter.height * .2*canvasWidth/multimeter.width));

    context.drawImage(diagram, .25*canvasWidth, 0, .5*canvasWidth, (diagram.height * .5*canvasWidth/diagram.width));


    if (toolSelected === "resistor") {
      context.fillStyle = "#aaaaaa";
      context.fillRect( .8*canvasWidth - 5, 10 - 5, .15*canvasWidth + 10, (resistor.height * .15*canvasWidth/resistor.width) + 10 );
    }
    context.drawImage(resistor, .8*canvasWidth, 10, .15*canvasWidth, (resistor.height * .15*canvasWidth/resistor.width));

    let toggleImage = toggleOff;
    if (toggleIsOn) {
      toggleImage = toggleOn;
    }
    context.drawImage(toggleImage, .8*canvasWidth, .2*canvasHeight, .05*canvasWidth, (toggleImage.height * .05*canvasWidth/toggleImage.width))

    let breakerImage = breakerOff;
    if (breakerIsOn) {
      breakerImage = breakerOn;
    }
    context.drawImage(breakerImage, .9*canvasWidth, .2*canvasHeight, .05*canvasWidth, (breakerImage.height * .05*canvasWidth/breakerImage.width))

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    context.fillText(`Multimeter Readout: ${multimeterReadout}`, 0, canvasHeight/20);
    // context.fillText(mousePositionX.toString(), 0, 2*canvasHeight/20);
    // context.fillText(mousePositionY.toString(), 0, 3* canvasHeight/20);
    // context.fillText(multimeterSetting.toString(), 0, 4* canvasHeight/20);
  }

  useEffect(() => { // TODO: Add window resize listeners and mouse listeners.
    let intervalHandle:NodeJS.Timeout|null = null;
    var loaded = 0; // Counts up to the number of items; in this case, two.
    const onload = () => {
      loaded = loaded + 1;
      if (loaded < 9) { // TODO: This number changes based on the number of images.
        return;
      }

      draw();

      intervalHandle = setInterval(draw, 1000/24);
    }

    multimeter.onload = onload;
    diagram.onload = onload;
    breakerOn.onload = onload;
    breakerOff.onload = onload;
    resistor.onload = onload;
    toggleOn.onload = onload;
    toggleOff.onload = onload;
    redLead.onload = onload;
    blackLead.onload = onload;

    multimeter.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/ClampMultimeter.png";
    diagram.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/LineDiagram.jpg";
    breakerOn.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/BreakerOn.png";
    breakerOff.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/BreakerOff.png";
    resistor.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/DischargeResistor.png";
    toggleOn.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/ToggleOn.png";
    toggleOff.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/ToggleOff.png";
    redLead.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/RedLead.png";
    blackLead.src = "./assets/tasks/ElectricalTroubleshooting/SourceImages/BlackLead.png";


    return (()=>{
      if (intervalHandle) {
        clearInterval(intervalHandle);
      }
    })
  }); //[]

  const promptText = "The condenser on the main line is not working. Use your tools to isolate and diagnose the problem.";

  return (
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>Skills Assessment</IonTitle>
    //       <IonButtons slot="start">
    //         <IonBackButton defaultHref="home" />
    //       </IonButtons>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent fullscreen>
    //     <IonHeader collapse="condense">
    //       <IonToolbar>
    //         <IonTitle size="large">Skills Assessment</IonTitle>
    //       </IonToolbar>
    //     </IonHeader>
        <IonCard style={{maxWidth: "initial"}}>
          <IonCardContent>
            {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
            {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}
            <br/>

            <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick}/>
            <IonList>
              <IonItem>
                <IonLabel>Multimeter Setting</IonLabel>
                <IonSelect value={multimeterSetting} onIonChange={(event)=>{multimeterSetting = event.target.value;}}>
                  <IonSelectOption>V</IonSelectOption>
                  <IonSelectOption>mA (DC)</IonSelectOption>
                  <IonSelectOption>mA (AC)</IonSelectOption>
                  <IonSelectOption>µF</IonSelectOption>
                  <IonSelectOption>Ω</IonSelectOption>
                  <IonSelectOption>continuity</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>What is the cause of the malfunction?</IonLabel>
                <IonSelect placeholder="Test the components first." onIonChange={(event)=>{faultyComponent = event.target.value;}}>
                  <IonSelectOption>Faulty Capacitor</IonSelectOption>
                  <IonSelectOption>Obstructed Motor (Fan)</IonSelectOption>
                  <IonSelectOption>Obstructed Motor (Compressor)</IonSelectOption>
                  <IonSelectOption>Short Circuit</IonSelectOption>
                  <IonSelectOption>Wire Break</IonSelectOption>
                  <IonSelectOption>Broken Logical Controller</IonSelectOption>
                  <IonSelectOption>Bad Power Supply</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
            <IonButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("circuitTesting", {faultyComponent: faultyComponent, complete: true})}}>Submit</IonButton>
          </IonCardContent>
        </IonCard>
    //   </IonContent>
    // </IonPage>
  );
};

export default CircuitTestingSimulator;