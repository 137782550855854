import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import SynchDisabledButton from "../components/SynchDisabledButton";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";

export type position = [number, number, number, number]; // x, y, width, height.

const SolarDCHealth: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  let intervalHandleInit = null as NodeJS.Timeout|null;
  let intervalHandle = useRef(intervalHandleInit);

  const {width, height} = useWindowDimensions(); //Note: Uses a custom-width card to achieve this.

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const poleWidthUnits = .33; // A third of a meter.
  const poleHeightUnits = 12;

  const heightUnits = poleHeightUnits + .5 * 2; // 14 meter(s) -> 35 feet for the pole, 5 above, and 5 below.
  const widthUnits = aspectRatio * heightUnits;
  const unitsToPixels = canvasWidth / widthUnits;


  const poleWidthPixels = Math.max(poleWidthUnits * unitsToPixels, 5);
  const poleHeightPixels = poleHeightUnits * unitsToPixels;
  const leftoverHeight = canvasHeight - poleHeightPixels;

  const groundPosition = 1 - (heightUnits - poleHeightUnits)/(2*heightUnits);

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  const clickableRadiusUnits = heightUnits/20;

  const assets = {
    "background": {src: "./assets/tasks/SolarDCHealth/SourceImages/background.png"},
    "multimeterVolts": {src: "./assets/tasks/SolarDCHealth/SourceImages/multimeterVolts.png"},
    "multimeterAmps": {src: "./assets/tasks/SolarDCHealth/SourceImages/multimeterAmps.png"},
    "solarPanel": {src: "./assets/tasks/SolarDCHealth/SourceImages/solarPanel.jpg"},
  };

  const imagesInit = Object.fromEntries(Object.entries(assets).map(([key, value])=>{
    return [key, new Image()]
  })) as Record<keyof typeof assets, HTMLImageElement>;
  const images = imagesInit;

  const instancesInit: {name:keyof typeof assets, position: position, caption?:string, cover?:boolean}[] = [
    {name: "background", position: [0, 0, 1, 1], cover: true},
    {name: "solarPanel", position: [.3, .2, .15, .6]},
    {name: "solarPanel", position: [.5, .2, .15, .6]},
    {name: "solarPanel", position: [.7, .2, .15, .6]},
  ];

  const getCenterXY = (inputPosition: position):[number, number]=>{
    const centerX = inputPosition[0] + .5 * inputPosition[2];
    const centerY = inputPosition[1] + .5 * inputPosition[3];
    return [centerX, centerY];
  }

  // Define inverter location and wire nodes, then define selectable wire centers and solar panel centers.
  const inverterLocation:position = [.9, .45, .05, .1];

  let mousePositionXInit = 0;
  let mousePositionYInit = 0;

  const upperLeftNode: [number, number] = [.25, .1];
  const [inverterCenterX, inverterCenterY] = getCenterXY(inverterLocation);
  const upperRightNode: [number, number] = [inverterCenterX, .1];

  const solarLeftXY = getCenterXY(instancesInit[1].position);
  const solarMiddleXY = getCenterXY(instancesInit[2].position);
  const solarRightXY = getCenterXY(instancesInit[3].position);

  const leftNode: [number, number] = [upperLeftNode[0], solarLeftXY[1]];

  const wires: position[] = [];

  const selectableCenters: {x: number, y: number, unit: string, measurement: number}[] = [
  ];

  const positionFromTo = (center1: [number, number], center2: [number, number]):position=>{
    return [center1[0], center1[1], center2[0]-center1[0], center2[1]-center1[1]];
  }

  const createWire = (fromTo: position)=>{
    wires.push(fromTo);
    const [x, y] = getCenterXY(fromTo);
    selectableCenters.push({x:x, y:y, unit: "A", measurement: 25});
  };

  createWire(positionFromTo(solarLeftXY, solarMiddleXY));
  createWire(positionFromTo(solarMiddleXY, solarRightXY));
  createWire(positionFromTo(solarLeftXY, leftNode));
  createWire(positionFromTo(leftNode, upperLeftNode));
  createWire(positionFromTo(upperLeftNode, upperRightNode));
  createWire(positionFromTo(upperRightNode, [inverterCenterX, inverterCenterY]));
  createWire(positionFromTo(solarRightXY, [inverterCenterX, inverterCenterY]));

  wires.push([inverterCenterX, inverterCenterY, 0, 1-inverterCenterY]);

  [solarLeftXY, solarMiddleXY, solarRightXY].forEach((center, index)=>{
    selectableCenters.push({x: center[0], y: center[1], unit: "V", measurement: 12 * Math.pow(-1, index)});
  });

  const multimeterFractionPosition:position = [0, 0, .2, 1];

  // https://reactjs.org/docs/hooks-reference.html#useref
  // Ref for mutable state to persist across window resize.
  const simulatorState = useRef({ // Simulator State.
    instances: instancesInit,
    mousePositionX: mousePositionXInit,
    mousePositionY: mousePositionYInit,
    voltsSelected: false, // Multimeter setting.
    selectedIndex: null as null | number,
    identifiedCause: null as null | "",
  }); // TODO: Move images to a useMemo or something? Shouldn't wind up in the database-storable state. Currently images are reloaded every time the simulator reloads. Consider making a separate useRef or something and changing useEffect to not reload if they are already loaded.
  const state = simulatorState.current; // Shortform reference to the mutable object.

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth); // Fraction 0-1
    state.mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight); // Fraction 0-1

    // state.instances.forEach((instance, index)=>{
    //   if (checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
    //     instance.mouseover = true; // This updates the original object.
    //   } else {
    //     instance.mouseover = false;
    //   }
    // });
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:position) => {
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  const fractionToRealPositions = ([fractionX, fractionY, fractionWidth, fractionHeight]:position):position => {
    return [fractionX * canvasWidth, fractionY * canvasHeight, fractionWidth * canvasWidth, fractionHeight * canvasHeight];
  };

  // Both of these assume square coordinate systems (pixel space, not fraction space).
  const containFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the bigger of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const biggerRatio = Math.max(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / biggerRatio;
    const chosenHeight = imageHeight / biggerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const coverFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the smaller of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const smallerRatio = Math.min(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / smallerRatio;
    const chosenHeight = imageHeight / smallerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const multimeterClickFractions: position = [.05, .2, .15, .8];
    if (checkInside(state.mousePositionX, state.mousePositionY, multimeterClickFractions)) {
      state.voltsSelected = !state.voltsSelected;
      state.selectedIndex = null;
    }

    // Update Selected Region
    selectableCenters.forEach((object, key)=>{
      const {x: centerX, y: centerY} = selectableCenters[key];
      const xInside = (state.mousePositionX <= centerX + clickableRadiusUnits/widthUnits) && (state.mousePositionX >= centerX - clickableRadiusUnits/widthUnits);
      const yInside = (state.mousePositionY <= centerY + clickableRadiusUnits/heightUnits) && (state.mousePositionY >= centerY - clickableRadiusUnits/heightUnits);
      if (xInside && yInside) {
        state.selectedIndex = key;
      }
    });
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw the background image
    const background = state.instances.filter((instance)=>{return instance.name==="background"});
    background.forEach((backgroundInstance)=>{
      const itemImage = images[backgroundInstance.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(backgroundInstance.position);
      const fitPosition = coverFit(itemImage.width, itemImage.height, targetPosition);

      context.drawImage(itemImage, ...fitPosition);
    })

    // Draw the wires, then the inverter. // TODO // Also, setting up measurable regions and setting the dropdown for cause analysis.
    context.strokeStyle="#000000";
    context.lineWidth = Math.max(height/100, 5);
    wires.forEach((wireFractions)=>{
      const wire = fractionToRealPositions(wireFractions);
      context.beginPath();
      context.moveTo(wire[0], wire[1]);
      context.lineTo(wire[0] + wire[2], wire[1] + wire[3]);
      context.stroke();
    });

    // Draw image instances in order.
    const nonBackgrounds = state.instances.filter((instance)=>{return instance.name!=="background"});
    nonBackgrounds.forEach((item)=>{

      const itemImage = images[item.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(item.position);
      const fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);

      // if (item.active) {
      //   const [fitX, fitY, fitWidth, fitHeight] = fitPosition;
      //   const border = 5; // px.
      //   context.fillStyle = "#999999";
      //   context.fillRect(fitX - border, fitY - border, fitWidth + 2*border, fitHeight + 2*border);
      // }

      // if (item.mouseover) {
      //   context.globalAlpha = .8;
      // }
      context.drawImage(itemImage, ...fitPosition);
      if (item.caption) {
        const captionHeight = canvasHeight/25; // Arbitrary. Still looks okay on iPhone.
        context.font = `${captionHeight}px sans serif`;
        const measure = context.measureText(item.caption);
        // Place Caption Background
        context.globalAlpha = .5;
        context.fillStyle = "#FFFFFF";
        context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3] - captionHeight, measure.width, captionHeight * 1.5); // Scale by 1.5 to cover below letters too.
        //context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3], 100, 100);
        context.globalAlpha = 1;
        // Place Caption
        context.strokeStyle = "#FFFFFF";
        context.strokeText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
        context.fillStyle = "#000000";
        context.fillText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
      }
      context.globalAlpha = 1;

      // Debugging only to adjust windows on dev screen:
      const debugging = false;
      if (window.location.hostname.toLowerCase()==="localhost" && debugging) { // can be turned on and off.
        context.strokeStyle = "#00FF00";
        context.strokeRect(...targetPosition);
        context.strokeStyle = "#0000FF";
        context.strokeRect(...fitPosition);
      }
    })

    // Draw the Inverter
    context.fillStyle = "#eeeeee";
    context.strokeStyle = "#000000";
    context.fillRect(...fractionToRealPositions(inverterLocation));

    // Draw the Multimeter
    const multimeterImageName = state.voltsSelected? "multimeterVolts" : "multimeterAmps";
    const itemImage = images[multimeterImageName];
    const imageHeight = itemImage.height;
    const imageWidth = itemImage.width;

    const targetPosition = fractionToRealPositions(multimeterFractionPosition);
    const fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);

    context.drawImage(itemImage, ...fitPosition);

    // Draw a box over the output and place measurement text:
    const screenPosition:position = [.07, .625, .092, .09];
    const screenPositionPixels = fractionToRealPositions(screenPosition);
    //context.fillRect(...screenPositionPixels);
    context.fillStyle = "#000000";
    const fontHeight = canvasHeight/20;
    context.font = `${fontHeight}px sans serif`;
    const multimeterText = (state.selectedIndex!==null? selectableCenters[state.selectedIndex].measurement : 0) + " " + (state.voltsSelected? "V" : "A");
    context.fillText(multimeterText, screenPositionPixels[0] + 5, screenPositionPixels[1] + screenPositionPixels[3] - .5 * fontHeight);

    // // Draw a highlight circle based on the active line.
    // if (state.activeLine) {
    //   context.fillStyle = state.linePositions[state.activeLine].color + "80" // Add 50% alpha.
    //   context.beginPath()
    //   context.arc(state.mousePositionX * canvasWidth, state.mousePositionY * canvasHeight, canvasHeight/20, 0, Math.PI * 2, true);
    //   context.fill();
    // }

    // Draw a highlight where each item is selectable, red if undetermined, green if set.
    selectableCenters.forEach((object, key)=>{
      const currentUnit = state.voltsSelected? "V" : "A";
      if (object.unit === currentUnit) {
        if (state.selectedIndex === key) {
          context.fillStyle = "#00FF0080"; // 50% alpha
        } else {
          context.fillStyle = "#FFFF0080"; // 50% alpha
        }
        context.beginPath()
        context.arc(selectableCenters[key].x * canvasWidth, selectableCenters[key].y * canvasHeight, clickableRadiusUnits * unitsToPixels, 0, Math.PI * 2, true);
        context.closePath();
        context.fill();
      }
    })

    // const weightReading = state.instances.reduce((cumulative, current)=>{
    //   if (current.active) {
    //     cumulative += current.weight;
    //   }
    //   return cumulative;
    // }, 0);
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      // `${state.handAboveWorker}, ${state.handCorrectSide}, ${state.tagOnTransformer}, ${state.tagCorrectSide}, ${state.pulleyAboveWorker}, ${state.pulleyOnTransformer}`,
      // `Selected Equipment Weight: ${weightReading.toFixed(1)} kg`,
      // `Mouse Position X: ${mousePositionX.toFixed(2)}`,
      // `Mouse Position Y: ${mousePositionY.toFixed(2)}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  }

  const drawLoaded = (numerator:number, denominator:number) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      `Simulator Components Loaded: ${numerator}/${denominator}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  };

  useEffect(() => {
    var loaded = 0; // Counts up to the number of items; note: images are reloaded (I think) every render.
    const onload = () => {
      loaded = loaded + 1;
      if (loaded < Object.keys(images).length) {
        drawLoaded(loaded, Object.keys(images).length); // Draw Loading Update.
        return;
      }

      draw();

      if (!intervalHandle.current) {
        intervalHandle.current = setInterval(draw, 1000/24);
      }
    }

    drawLoaded(loaded, Object.keys(images).length);

    let key: keyof typeof assets;
    for (key in images) {
      images[key].onload = onload
      images[key].src = assets[key].src;
    } // This initiates (I think) the reload of images, even if the src is the same.

    return (()=>{
      if (intervalHandle.current) {
        clearInterval(intervalHandle.current);
        intervalHandle.current = null;
      }
    })
  }); //[]

  const promptText = "There is not as much power coming out of the inverter as expected. Use the multimeter to identify the root cause of the problem.";

  return (
    // TODO: Add wrapping page for demo purposes?
    // TODO: Add a note about using landscape mode...
    <IonCard style={{maxWidth: "initial"}}>
      <IonCardContent>
        {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}

        <br/>
        <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick}/>

        <br />

        <IonItem>
          <IonLabel>What is the cause of the problem?</IonLabel>
          <IonSelect placeholder="Take some measurements first." value={state.identifiedCause} onIonChange={(event)=>{state.identifiedCause = event.target.value;}}>
            <IonSelectOption>Shorted Inverter</IonSelectOption>
            <IonSelectOption>Broken Wiring</IonSelectOption>
            <IonSelectOption>Left Panel Burned Out</IonSelectOption>
            <IonSelectOption>Center Panel Burned Out</IonSelectOption>
            <IonSelectOption>Right Panel Burned Out</IonSelectOption>
            <IonSelectOption>Left Panel Mis-Wired</IonSelectOption>
            <IonSelectOption value="centerMiswired">Center Panel Mis-Wired</IonSelectOption>
            <IonSelectOption>Right Panel Mis-Wired</IonSelectOption>
          </IonSelect>
        </IonItem>

        <SynchDisabledButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("solarDCHealth", {complete: true, state: state})}}>Submit</SynchDisabledButton>
        {/* TODO: Move submit button to a wrapper element? */}

        {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
        {width < height? <IonText color="danger"><p>For best performance, consider using landscape mode.</p></IonText> : <></>}
      </IonCardContent>
    </IonCard>
  );
};

export default SolarDCHealth;