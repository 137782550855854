import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { addCircle, closeCircle } from "ionicons/icons";
import { useState } from "react";

export const filterEmpties = (stringList: string[])=>{return stringList.filter((item)=>{return item.trim().length > 0;})};

const TextListInput: React.FC<{defaultValues:string[], onUpdate:(newList:string[])=>void}> = ({defaultValues, onUpdate, ...props}) => {
  const [customItems, setCustomItems] = useState(defaultValues as string[]);
  // This step has to be done by the calling/parent context.
  // const nonemptyCustomSkills = customItems.filter((skill)=>{return skill.trim().length > 0});

  return <>
      {customItems.map((skill, index)=>{
      return <IonItem key={index}>
        <IonInput value={skill} placeholder="enter skill name" onInput={(event)=>{customItems[index]=""+event.currentTarget.value; setCustomItems([...customItems]);  onUpdate([...customItems]);}}/>
        <IonIcon slot="end" icon={closeCircle} color="danger" onClick={()=>{customItems.splice(index, 1); setCustomItems([...customItems]); onUpdate([...customItems]);}}/>
      </IonItem>
    })}
    <IonItem onClick={()=>{setCustomItems([...customItems, ""]); onUpdate([...customItems]);}}>
      <IonIcon icon={addCircle} slot="start" color="primary"/>
      <IonLabel>(Add an Item)</IonLabel>
    </IonItem>
    <br />
  </>
}

export default TextListInput;