import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import SynchDisabledButton from "../components/SynchDisabledButton";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";

export type position = [number, number, number, number]; // x, y, width, height.

const TransformerKnots: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  let intervalHandleInit = null as NodeJS.Timeout|null;
  let intervalHandle = useRef(intervalHandleInit);

  const {width, height} = useWindowDimensions(); //Note: Uses a custom-width card to achieve this.

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const heightUnits = 12 + 2 + 2; // 14 meter(s) -> 35 feet for the pole, 5 above, and 5 below.
  const widthUnits = aspectRatio * heightUnits;
  const unitsToPixels = canvasWidth / widthUnits;

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  // Define pole parameters.
  const poleWidthUnits = .33; // A third of a meter.
  const poleWidthPixels = Math.max(poleWidthUnits * unitsToPixels, 5);
  const poleLengthUnits = 12;
  const poleLengthPixels = poleLengthUnits * unitsToPixels;
  const verticalOffsetFraction = 19/20;
  const poleCoords:position = [canvasWidth/2, canvasHeight * verticalOffsetFraction, poleLengthPixels, poleWidthPixels];

  const assets = {
    "background": {src: "./assets/tasks/TransformerKnots/SourceImages/background.png"},
    "granny": {src: "./assets/tasks/TransformerKnots/SourceImages/grannyKnot.png"},
    "square": {src: "./assets/tasks/TransformerKnots/SourceImages/squareKnot.png"},
    "surgeon": {src: "./assets/tasks/TransformerKnots/SourceImages/surgeonsKnot2.png"},
    "larksHead": {src: "./assets/tasks/TransformerKnots/SourceImages/larksHead.png"},
    "twoHalfHitches": {src: "./assets/tasks/TransformerKnots/SourceImages/twoHalfHitches.png"},
    "tautline": {src: "./assets/tasks/TransformerKnots/SourceImages/tautline.png"},
  };


  const imagesInit = Object.fromEntries(Object.entries(assets).map(([key, value])=>{
    return [key, new Image()]
  })) as Record<keyof typeof assets, HTMLImageElement>;
  // Add background
  const images = imagesInit;

  const instancesInit: {name:keyof typeof assets, position: position, caption?:string, cover?:boolean, group?:string}[] = [
    {name: "background", position: [0, 0, 1, 1], cover: true},
    {name: "granny", position: [.25, .05, .2, .3], group: "top"},
    {name: "square", position: [.5, .05, .2, .3], group: "top"},
    {name: "surgeon", position: [.75, .05, .2, .3], group: "top"},
    {name: "larksHead", position: [.25, .65, .2, .3], group: "tag"},
    {name: "twoHalfHitches", position: [.5, .65, .2, .3], group: "tag"},
    {name: "tautline", position: [.75, .65, .2, .3], group: "tag"},
  ];

  let mousePositionXInit = 0;
  let mousePositionYInit = 0;

  const selectedKnotsInit: {[key:string]: string} = {};

  // https://reactjs.org/docs/hooks-reference.html#useref
  // Ref for mutable state to persist across window resize.
  const simulatorState = useRef({ // Simulator State.
    instances: instancesInit,
    mousePositionX: mousePositionXInit,
    mousePositionY: mousePositionYInit,
    selectedKnots: selectedKnotsInit,
  }); // TODO: Move images to a useMemo or something? Shouldn't wind up in the database-storable state. Currently images are reloaded every time the simulator reloads. Consider making a separate useRef or something and changing useEffect to not reload if they are already loaded.
  const state = simulatorState.current; // Shortform reference to the mutable object.

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth); // Fraction 0-1
    state.mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight); // Fraction 0-1
  }

  const mouseOut = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>)=>{
    state.mousePositionX = 0;
    state.mousePositionY = 0;
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:position) => {
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  const fractionToRealPositions = ([fractionX, fractionY, fractionWidth, fractionHeight]:position):position => {
    return [fractionX * canvasWidth, fractionY * canvasHeight, fractionWidth * canvasWidth, fractionHeight * canvasHeight];
  };

  // Both of these assume square coordinate systems (pixel space, not fraction space).
  const containFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the bigger of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const biggerRatio = Math.max(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / biggerRatio;
    const chosenHeight = imageHeight / biggerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const coverFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the smaller of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const smallerRatio = Math.min(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / smallerRatio;
    const chosenHeight = imageHeight / smallerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    // Add a hole if at a relevant position.
    state.instances.forEach((instance)=>{
      if(instance.group) {
        if(checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
          state.selectedKnots[instance.group] = instance.name;
        }
      }
    })
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    // The image background is drawn on top of it.

    // Draw image instances in order.
    state.instances.forEach((item)=>{
      const itemImage = images[item.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(item.position);
      let fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);
      if (item.cover) {
        fitPosition = coverFit(itemImage.width, itemImage.height, targetPosition);
      }

      context.drawImage(itemImage, ...fitPosition);
      if (item.caption) {
        const captionHeight = canvasHeight/25; // Arbitrary. Still looks okay on iPhone.
        context.font = `${captionHeight}px sans serif`;
        const measure = context.measureText(item.caption);
        // Place Caption Background
        context.globalAlpha = .5;
        context.fillStyle = "#FFFFFF";
        context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3] - captionHeight, measure.width, captionHeight * 1.5); // Scale by 1.5 to cover below letters too.
        //context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3], 100, 100);
        context.globalAlpha = 1;
        // Place Caption
        context.strokeStyle = "#FFFFFF";
        context.strokeText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
        context.fillStyle = "#000000";
        context.fillText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
      }
      context.globalAlpha = 1;

      // Debugging only to adjust windows on dev screen:
      const debugging = false;
      if (window.location.hostname.toLowerCase()==="localhost" && debugging) { // can be turned on and off.
        context.strokeStyle = "#00FF00";
        context.strokeRect(...targetPosition);
        context.strokeStyle = "#0000FF";
        context.strokeRect(...fitPosition);
      }

      if (item.name === (state.selectedKnots?.[item.group || ""]|| "")) {
        context.lineWidth = 5;
        context.strokeStyle = "#00FF00";
        context.strokeRect(...fitPosition);
        context.lineWidth = 1;
      } else {
        context.lineWidth = 2;
        context.strokeStyle = "#000000";
        context.strokeRect(...fitPosition);
        context.lineWidth = 1;
      }
    })


    // Add help text.
    context.fillStyle = "#FFFFFF";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      // `${state.handAboveWorker}, ${state.handCorrectSide}, ${state.tagOnTransformer}, ${state.tagCorrectSide}, ${state.pulleyAboveWorker}, ${state.pulleyOnTransformer}`,
      // `Selected Equipment Weight: ${weightReading.toFixed(1)} kg`,
      // `Mouse Position X: ${mousePositionX.toFixed(2)}`,
      // `Mouse Position Y: ${mousePositionY.toFixed(2)}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  }

  const drawLoaded = (numerator:number, denominator:number) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      `Simulator Components Loaded: ${numerator}/${denominator}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  };

  useEffect(() => {
    var loaded = 0; // Counts up to the number of items; note: images are reloaded (I think) every render.
    const onload = () => {
      loaded = loaded + 1;
      if (loaded < Object.keys(images).length) {
        drawLoaded(loaded, Object.keys(images).length); // Draw Loading Update.
        return;
      }

      draw();

      if (!intervalHandle.current) {
        intervalHandle.current = setInterval(draw, 1000/24);
      }
    }

    drawLoaded(loaded, Object.keys(images).length);

    let key: keyof typeof assets;
    for (key in images) {
      images[key].onload = onload
      images[key].src = assets[key].src;
    } // This initiates (I think) the reload of images, even if the src is the same.

    return (()=>{
      if (intervalHandle.current) {
        clearInterval(intervalHandle.current);
        intervalHandle.current = null;
      }
    })
  }); //[]

  const promptText = "The transformer needs to be rigged with a tagline and top rope for lifting. Please select appropriate knots for the top and side of the transformer.";
  // https://www.youtube.com/watch?v=vGp4LKbikC0

  return (
    // TODO: Add wrapping page for demo purposes?
    // TODO: Add a note about using landscape mode...
    <IonCard style={{maxWidth: "initial"}}>
      <IonCardContent>
        {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}
        
        <br/>
        <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick} onMouseOut={mouseOut}/>
        <br />

        <SynchDisabledButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("knots", {complete: true, state: state})}}>Submit</SynchDisabledButton>
        {/* TODO: Move submit button to a wrapper element? */}

        {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
        {width < height? <IonText color="danger"><p>For best performance, consider using landscape mode.</p></IonText> : <></>}
      </IonCardContent>
    </IonCard>
  );
};

export default TransformerKnots;