import { useState, useEffect } from 'react';

// export const domainsList = [
//   'hr.powertechs.us',
//   'jobs.powertechs.us'
// ] as const; // https://stackoverflow.com/questions/51521808/how-do-i-create-a-type-from-a-string-array-in-typescript

// export type domain = typeof domainsList[number];

// function getCurrentDomain():domain {
//   let domainName = window.location.hostname.toLowerCase();
//   // domainName = (domainName === "localhost"? "jobs.powertechs.us" : domainName);
//   let collapsedName = (domainName in domainsList? domainName : "jobs.powertechs.us") as domain;
//   return collapsedName;
// }

export const domainMap = {
  "jobs.powertechs.us": "jobs",
  "hr.powertechs.us": "hr",
  "demo.powertechs.us": "demo",
  "linemen.powertechs.us": "linemen",
  "solartechs.powertechs.us": "solartechs",
  "windtechs.powertechs.us": "windtechs",
  "solar.powertechs.us": "solar-demo",
  "wind.powertechs.us": "wind-demo",
} as const; // https://stackoverflow.com/questions/51521808/how-do-i-create-a-type-from-a-string-array-in-typescript

type DomainURL = keyof typeof domainMap;
export type AppDomain = typeof domainMap[DomainURL];

// Mildly tedious finagling to get type inference right.
// https://dev.to/hansott/how-to-check-if-string-is-member-of-union-type-1j4m
const isDomainURL = (value: string): value is DomainURL => {
  return (Object.keys(domainMap)).includes(value); // may need to typecast as as string[] for values.
}

function getCurrentDomain():AppDomain {
  let domainName = window.location.hostname.toLowerCase();
  // domainName = (domainName === "localhost"? "jobs.powertechs.us" : domainName);
  let collapsedName:DomainURL = (isDomainURL(domainName)? domainName : "linemen.powertechs.us");
  return domainMap[collapsedName];
}

export default function useSubdomain() {
  // const [appSubdomain, setAppSubdomain] = useState(getCurrentDomain());

  // useEffect(() => {
  //   function handleNavigate() {
  //     setAppSubdomain(getCurrentDomain());
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // TODO: Smarter event listening? Caching? Note that code above is partly converted from windowDimensions events.

  return getCurrentDomain();
}