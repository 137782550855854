import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useRef, useState } from "react";
import { functions } from "../App";
import { useAuth } from "./AuthProvider";
import useSubdomain from "./SubdomainMapper";
import {isAdmin, Quality, RoleSkill, ServerQuestion} from 'common';

const stubContext = {
  profile:{} as any, 
  setProperty: (name:string, value:any)=>{return {} as Promise<any>}, 
  synching:false, 
  serverFetchHRRecords: (data:any)=>{return {} as Promise<any>},
  serverFetchLeaderboardData: (data:any)=>{return {} as Promise<any>},
  queryParameters: {} as {
    [key: string]: string;
  },
  serverAdminCreateQuestion: (data: {record:ServerQuestion})=>{return {} as Promise<any>},
  roleToSkillNames: {} as {[key: string]:string[]},
  skillNamesToQuestionIDs: {} as {[key:string]:string[]},
  questionIDsToQuestions: {} as {[key:string]:ServerQuestion},
  skillIDsToSkills: {} as {[key:string]:Quality},
}
export const DataContext = React.createContext(stubContext); //TODO: Type context //TODO: Type for questions.

export function useData() {
  return useContext(DataContext);
}

/**
 * A helper component that emulates the DataProvider, but without actually storing anything on the server.
 * Useful for demo purposes.
 * @param param0 
 * @returns 
 */
export const TempDataProvider: React.FC = ({children, ...props}) => {
  let [profile, setProfile] = useState({} as any);
  const [synching, setSynching] = useState(false);

  const setProperty = async (name:string, value:any) => {
    setSynching(true);
    let newProfile = {} as any;
    Object.assign(newProfile, profile);
    newProfile[name] = value;
    profile = newProfile; // Allow multiple mutations before the changes activate. // TODO: long-term, change to a reducer/dispatch model?
    setProfile(newProfile);
    // serverCreateUpdateOwnedRecord({collectionName: "profiles", record: newProfile}).then((result)=>{
    //   newProfile = result.data;
    //   setProfile(newProfile);
    // }).catch((reason)=>{
    //   setSynching(false); // May be necessary since throw command follows?
    //   throw reason;
    // }).finally(()=>{
    //   setSynching(false);
    // })
    setSynching(false); // Never actually changes, since all of these steps are synchronous.
    return newProfile;
  };

  // useEffect(()=>{
  //   if (!currentUser) {
  //     setProfile({});
  //   } else {
  //     setSynching(true);
  //     serverFetchOwnedRecords({collectionName: "profiles"}).then((result)=>{
  //       setProfile((result.data as any[])[0] || {})
  //     }).finally(()=>{
  //       setSynching(false);
  //     })
  //   }
  // }, [currentUser]);

  return ( 
    <DataContext.Provider value={{...stubContext, profile, setProperty, synching}}>
      {children}
    </DataContext.Provider>
   );
}

export const DataProvider: React.FC = ({children, ...props}) => {
  const {currentUser} = useAuth();
  let [profile, setProfile] = useState({} as any);
  const [synchingProfile, setSynchingProfile] = useState(false);
  const subdomain = useSubdomain();
  const queryParameters = useRef({} as {[key: string]: string});
  const [qualities, setQualities] = useState([] as Quality[]);
  const [roleSkills, setRoleSkills] = useState([] as RoleSkill[]);
  const [questions, setQuestions] = useState([] as ServerQuestion[]);
  const [synchingMetadata, setSynchingMetadata] = useState(false);
  const synching = synchingProfile || synchingMetadata;

  // Read metadata, structure as maps, expose to context consumers.
  const skillIDsToSkills:{[key:string]:Quality} = {};
  qualities.forEach((quality, index)=>{
    skillIDsToSkills[quality.id || `No ID: ${index}`] = quality;
  });

  const skillNamesToQuestionIDs: {[key:string]:string[]} = {};
  const questionIDsToQuestions:{[key:string]:ServerQuestion} = {};
  questions.forEach((question, index) => {
    const qID = question.id || `No ID: ${index}`;
    questionIDsToQuestions[qID] = question;
    question.qualityIDs.forEach((qualityID)=>{
      const qualityName = skillIDsToSkills[qualityID]?.nameForCandidates || "Unidentified Skill";
      skillNamesToQuestionIDs[qualityName] ||= [];
      skillNamesToQuestionIDs[qualityName].push(qID);
    })
  });

  const roleToSkillNames: {[key: string]:string[]} = {};
  for (const roleSkill of roleSkills) {
    const skillNames: {[key:string]:boolean} = {};
    roleSkill.skillsRequired.forEach((skillID)=>{
      skillNames[ skillIDsToSkills[skillID]?.nameForCandidates || "Unidentified Skill"] = true;
    })
    roleToSkillNames[roleSkill.role] = Object.keys(skillNames);
  }

  const serverCreateUpdateOwnedRecord = httpsCallable(functions, "createUpdateOwnedRecord");
  const serverFetchOwnedRecords = httpsCallable(functions, "fetchOwnedRecords");
  const serverFetchHRRecords = httpsCallable(functions, "fetchHRRecords"); // Strictly pull request doesn't need to affect synching - it just returns a promise.
  const serverFetchLeaderboardData = httpsCallable(functions, "fetchLeaderboardData");
  const serverFetchMetadata = httpsCallable(functions, "fetchMetadata");
  const _serverAdminCreateQuestion = httpsCallable(functions, "adminCreateQuestion"); // Hidden internal reference to the function.
  let serverAdminCreateQuestion;
  if (isAdmin(currentUser?.email || "")) {
    serverAdminCreateQuestion = async (data:{record:ServerQuestion})=>{
      setSynchingMetadata(true); // TODO: Potential for race condition if useEffect and this are called at the same time.
      _serverAdminCreateQuestion({...data, unique:false}).then(()=>{
        serverFetchMetadata({}).then((result)=>{
          const {qualities, questions, roleSkills} = result.data as {questions:ServerQuestion[], qualities:Quality[], roleSkills: RoleSkill[]};
          setQualities(qualities);
          setRoleSkills(roleSkills);
          setQuestions(questions);
        }).finally(()=>{
          setSynchingMetadata(false); // TODO: This may never be run if the outer call fails.
        });
      })
    };
  } else {
    serverAdminCreateQuestion = stubContext.serverAdminCreateQuestion;
  }

  const setProperty = async (name:string, value:any) => {
    // TODO: Queue changes and prevent multiple parallel calls to serverCreateUpdateOwnedRecord
    setSynchingProfile(true);
    let newProfile = {} as any;
    Object.assign(newProfile, profile);
    // Splce Query Params into profile.
    if (newProfile.queryParameters) {
      const currentDate = new Date();
      const dateString = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()}`;
      //newProfile.queryParameters = {dateString: queryParameters.current, ...newProfile.queryParameters}
      newProfile.queryParameters[dateString] = queryParameters.current;
    } else {
      newProfile.queryParameters = queryParameters.current;
    }
    newProfile[name] = value;
    //profile = newProfile; // BUG: Doesn't modify shared property. 
    
    Object.assign(profile, newProfile)// Allow multiple mutations before the changes activate. // TODO: long-term, change to a reducer/dispatch model?
    
    setProfile(newProfile);
    //TODO: Synch/queue multiple updates (multiple calls to setProfile) by locking out calls until the previous promise returns.
    serverCreateUpdateOwnedRecord({collectionName: "profiles", record: newProfile}).then((result)=>{
      newProfile = result.data;
      setProfile(newProfile);
      queryParameters.current = {}; // Clear Query Parameter Cache.
    }).catch((reason)=>{
      setSynchingProfile(false); // May be necessary since throw command follows?
      throw reason;
    }).finally(()=>{
      setSynchingProfile(false);
    })
    return newProfile;
  };

  useEffect(()=>{
    if (!currentUser) {
      setProfile({});
    } else {
      setSynchingProfile(true);
      serverFetchOwnedRecords({collectionName: "profiles"}).then((result)=>{
        setProfile((result.data as any[])[0] || {})
      }).finally(()=>{
        setSynchingProfile(false);
      })
    }

    // No matter what, load the metadata.
    setSynchingMetadata(true);
    serverFetchMetadata({}).then((result)=>{
      const {qualities, questions, roleSkills} = result.data as {questions:ServerQuestion[], qualities:Quality[], roleSkills: RoleSkill[]};
      setQualities(qualities);
      setRoleSkills(roleSkills);
      setQuestions(questions);
    }).finally(()=>{
      setSynchingMetadata(false);
    })
  }, [currentUser]);

  return ( 
    <DataContext.Provider value={{profile, setProperty, synching, serverFetchHRRecords, serverFetchLeaderboardData, queryParameters: queryParameters.current, serverAdminCreateQuestion, roleToSkillNames, skillNamesToQuestionIDs, questionIDsToQuestions, skillIDsToSkills}}>
      {children}
    </DataContext.Provider>
   );
}

export default DataProvider;