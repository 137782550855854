import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonGrid, IonRouterLink, IonRow, IonText } from "@ionic/react";
import { combineGradeMetrics, getDefaultGradeMetrics, gradeFraming, gradeKnots, gradeLoads, gradeSolarDCHealth, gradeSolarEquipment, gradeSolarHotspots } from "common";
import React, { useState } from "react";
import DistributionEquipmentSimulator, { position } from "../pages/DistributionEquipmentSimulator";
import DistributionRopes from "../pages/DistributionRopes";
import EquipmentLoads from "../pages/EquipmentLoads";
import FramingHoles from "../pages/FramingHoles";
import SolarDCHealth from "../pages/SolarDCHealth";
import SolarEquipmentSimulator from "../pages/SolarEquipmentSimulator";
import SolarHotspots from "../pages/SolarHotspots";
import TopTieSimulator from "../pages/TopTieSimulator";
import TransformerKnots from "../pages/TransformerKnots";
import { useData } from "../services/DataProvider";

const randomizePercentage = (inputPercent:number, variance = .05) => {
  return inputPercent + Math.random()*variance*(1-inputPercent) - Math.random()*variance*inputPercent;
}

const randomizeFraction = (numerator:number, denominator:number) => {
  let newDenominator = denominator<=0? 1 : denominator;
  return randomizePercentage(numerator/newDenominator);
}

const SolarDemo: React.FC = () => {
  const {profile, setProperty} = useData();

  let currentComponent = <IonCard>
    <IonCardHeader><IonCardTitle>Welcome to the Solar Technician Demo!</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>You are about take a short assessment designed to measure solar technician skills. Afterward, you will be presented with a sample debrief screen as would be seen by a training manager.</p>
      <IonButton onClick={()=>{setProperty("introScreen", {shown: true})}}>Continue</IonButton>
    </IonCardContent>
  </IonCard>

  const simulators:{[key: string]: any} = {
    "solarEquipment": <SolarEquipmentSimulator />,
    "solarHotspots": <SolarHotspots />,
    "solarDCHealth": <SolarDCHealth />,
  };

  const setGradingComponent = ()=>{
    const graders:{[key: string]: any} = {
      "solarEquipment": gradeSolarEquipment,
      "solarHotspots": gradeSolarHotspots,
      "solarDCHealth": gradeSolarDCHealth,
    };

    let metrics = getDefaultGradeMetrics();

    let simulator: keyof typeof graders;
    for (let simulator in graders) {
      const gradeResult = graders[simulator](profile);
      metrics = combineGradeMetrics(metrics, gradeResult)
    }

    currentComponent = <IonCard>
      <IonCardHeader><IonCardTitle>Post-Simulator Debrief</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <p>This is what a training manager would see after an employee or job candidate completed the simulated tasks. Please note that all tasks and insights can be customized to your needs.</p>
        <br />
        <h1>Competence</h1>
        <h3>Overall: {(randomizeFraction(metrics.technicalEarned, metrics.technicalAvailable)*100).toFixed(2)}%</h3>
        {(metrics.hrGradingNotes.length > 0) && <>
          {metrics.hrGradingNotes.map((gradeNote, index)=>{
            const filteredComments = gradeNote.comments.filter((comment, index)=>{
              return comment.commentType==="competence";
            });
            if (filteredComments.length <= 0) {
              return <div key={index}></div>
            } else {
              return <div key={index}>
                <h3>{gradeNote.skillName}{filteredComments.length > 0 && <>:</>}</h3>
                <ul>
                  {filteredComments.map((comment, innerIndex)=>{return <li key={innerIndex}><IonText color={comment.status==="acceptable"?"" : comment.status}>{comment.comment}</IonText></li>})}
                </ul>
              </div>
            }
          })}
        </>}
        <br />

        <h1>Safety</h1>
        <h3>Overall: {(randomizeFraction(metrics.safetyEarned, metrics.safetyAvailable)*100).toFixed(2)}%</h3>
        {(metrics.hrGradingNotes.length > 0) && <>
          {metrics.hrGradingNotes.map((gradeNote, index)=>{
            const filteredComments = gradeNote.comments.filter((comment, index)=>{
              return comment.commentType==="safety";
            });
            if (filteredComments.length <= 0) {
              return <div key={index}></div>
            } else {
              return <div key={index}>
                <h3>{gradeNote.skillName}{filteredComments.length > 0 && <>:</>}</h3>
                <ul>
                  {filteredComments.map((comment, innerIndex)=>{return <li key={innerIndex}><IonText color={comment.status==="acceptable"?"" : comment.status}>{comment.comment}</IonText></li>})}
                </ul>
              </div>
            }
          })}
        </>}
        <br />

        {metrics.employabilityAvailable > 0 && <>
          <h1>Other</h1>
          <h3>Overall: {(randomizeFraction(metrics.employabilityEarned, metrics.employabilityAvailable)*100).toFixed(2)}%</h3>
          <br />
        </>}

        {(metrics.hrGradingNotes.length > 0) && <>
          {metrics.hrGradingNotes.map((gradeNote, index)=>{
            const filteredComments = gradeNote.comments.filter((comment, index)=>{
              return !["competence", "safety"].includes(comment.commentType || "");
            });
            if (filteredComments.length <= 0) {
              return <div key={index}></div>
            } else {
              return <div key={index}>
                <h3>{gradeNote.skillName}{filteredComments.length > 0 && <>:</>}</h3>
                <ul>
                  {filteredComments.map((comment, innerIndex)=>{return <li key={innerIndex}><IonText color={comment.status==="acceptable"?"" : comment.status}>{comment.comment}</IonText></li>})}
                </ul>
              </div>
            }
          })}
        </>}

        <br />
        <IonButton onClick={()=>{setProperty("reviewScreen", {shown: true});}}>Continue</IonButton>
      </IonCardContent>
    </IonCard>
  } // End setGradingComponent

  if (profile?.["introScreen"]?.["shown"]) {
    let simName: keyof typeof simulators;
    let simulatorAssigned = false;
    for (simName in simulators) {
      if (!profile?.[simName]?.["complete"]) {
        currentComponent = simulators[simName];
        simulatorAssigned = true;
        break;
      }
    }
    if (!simulatorAssigned) {
      setGradingComponent();
    }
  }

  if (profile?.["reviewScreen"]?.["shown"]) {
    const names = ["Sam", "Steven", "John Paul", "Robert", "Louis", "Derrick", "Hubert", "Jacob"];
    const roleGrades = names.map((name)=>{
      const technical = Math.sqrt(Math.random());
      const safety = Math.sqrt(Math.random());
      const team = Math.sqrt(Math.random());
      const match = (2*technical + safety + team)/4;


      return {
        name: name,
        technical,
        safety,
        team,
        match
      }
    }).sort((grade1, grade2)=>{
      return grade2.match-grade1.match;
    }); // Descending Sort

    // Show HR Ranking Page
    currentComponent = <>
      <IonCard style={{maxWidth: "initial", overflowX: "auto"}}>
        <IonCardHeader><IonCardTitle>Manager Dashboard</IonCardTitle></IonCardHeader>
        <IonCardContent>
          <h1 style={{textAlign:"center"}}>Select Role</h1>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow>
            {["Solar Technicians", "I&C Technicians", "Medium Voltage Splicers"].map((role, index)=>{
              return <IonCol key={index}><IonButton color={role==="Distribution Linemen"? "primary" : "secondary"} disabled={role!=="Solar Technicians"}>{role}</IonButton></IonCol>
            })}
          </IonRow></IonGrid>

          {/* {roleRecords?.[0]? JSON.stringify(roleRecords[0]): <></>} */}
          <table style={{margin:"auto"}}>
          <thead>
            <tr>
              <td><IonCheckbox disabled={true}/></td>
              <td>ID {/*(click to view profile)*/}</td>
              <td>Overall Score</td>
              <td>Technical Knowledge</td>
              <td>Safety Compliance</td>
              <td>Team Work</td>
              <td>Contact</td>
            </tr>
          </thead>
          <tbody>
            {/* <IonText color="success">✓</IonText> */}
            {roleGrades.map((record, index)=>{
              return <tr key={index}>
                <td><IonCheckbox /></td>
                <td><IonRouterLink title='Set up billing to access detailed profiles.'>{record.name}</IonRouterLink></td>
                <td><IonText color={record.match > .70? "success" : "warning"}>{(record.match * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.technical * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.safety * 100).toFixed(0)}%</IonText></td>
                <td><IonText>{(record.team * 100).toFixed(0)}%</IonText></td>
                <td><IonButton color="primary" title='Upgrade to a premium account to access candidate contact information.' disabled={true}>Contact</IonButton></td>
              </tr>
            })}
          </tbody>
          </table>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow><IonCol><IonButton color="secondary" title='Upgrade to a premium account to access candidate contact information.' disabled={true}>Download CSV</IonButton></IonCol></IonRow></IonGrid>

        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow><IonCol>
            <IonButton onClick={()=>{Object.keys(profile).forEach((propertyName)=>{setProperty(propertyName, null)})}}>Demo: Return to the beginning</IonButton>
          </IonCol></IonRow></IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  }

  // TODO: Remove
  // currentComponent = <SolarDCHealth />

  return (<>
    {currentComponent}
  </>);
};

export default SolarDemo;