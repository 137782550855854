import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
import { useHistory } from "react-router";
// import { useAuth } from "reactfire";
import { useAuth } from "../services/AuthProvider";

const UpdateProfile: React.FC = ({...props}) => {
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const confirmPasswordRef = useRef<HTMLIonInputElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {currentUser, updateEmail, updatePassword} = useAuth(); 
  // const auth = useAuth();

  const history = useHistory();

  function handleSubmitEmail(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    setError('');
    setLoading(true);

    const promises = [];
    if (emailRef.current?.value !== currentUser?.email) {
      promises.push(updateEmail("" + emailRef.current?.value)) //Doesn't actually allow updating both; I think because of interference between the two update methods.
    }

    Promise.all(promises).then(()=>{
      setLoading(false);
      history.push("/");
    }).catch((reason)=>{
      setLoading(false);
      setError("Failed to update account:" + reason.message || "");
    })
  }

  function handleSubmitPassword(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
      setError("Passwords do not match.");
      return;
    }

    setError('');
    setLoading(true);

    const promises = [];
    if (passwordRef.current?.value) {
      promises.push(updatePassword("" + passwordRef.current.value)) //Doesn't actually allow updating both; I think because of interference between the two update methods.
    }

    Promise.all(promises).then(()=>{
      setLoading(false);
      history.push("/");
    }).catch((reason)=>{
      setLoading(false);
      setError("Failed to update account:" + reason.message || "");
    })
  }

  //TODO: Set color of error based on ionic colors
  return <IonCard>
    <IonCardHeader><IonCardTitle>Update Email/Password</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmitEmail}>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput type="email" ref={emailRef} placeholder="example: first.last@gmail.com" value={"" + currentUser?.email}/>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Update Email
      </IonButton>
      </form>
      <form onSubmit={handleSubmitPassword}>
      <IonItem>
        <IonLabel position="floating">Password</IonLabel>
        <IonInput type="password" ref={passwordRef} placeholder="Leave blank to keep the same"/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Confirm New Password</IonLabel>
        <IonInput type="password" ref={confirmPasswordRef}/>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Update Password
      </IonButton>
      </form>
      <p><IonRouterLink routerLink="/">Back to Home</IonRouterLink></p>
    </IonCardContent>
  </IonCard>
};

export default UpdateProfile;

