import { IonContent, IonPage, IonRouterOutlet } from "@ionic/react"
import { Route, Switch } from "react-router";
import Login from "../components/Login";
import LogIn from "../components/Login";
import SignUp from "../components/SignUp";

const JobsLandingPage: React.FC = () => {
  const sharedStyles = {"--background":"url('/assets/splash/WindAndSolar.png') no-repeat center center / cover"};

  return (
    <IonPage>
      <IonContent style={sharedStyles}>
        <Switch>
          <Route exact path="/login">
            <LogIn />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
        </Switch>
      </IonContent>
    </IonPage>
  );
};

export default JobsLandingPage;