import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonPage, IonRouterLink, IonText } from "@ionic/react";
import { restaurantOutline } from "ionicons/icons";
import { FormEvent, useRef, useState } from "react";
import { useAuth } from "../services/AuthProvider";

const ForgotPassword: React.FC = ({...props}) => {
  const emailRef = useRef<HTMLIonInputElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const {resetPassword} = useAuth(); 
  // const auth = useAuth();

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    setError('');
    setMessage("");
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    await (resetPassword("" + emailRef.current?.value)?.then(()=>{
      setMessage("Check your email inbox for further instructions.")
    }).catch((reason)=>{
      setError('Failed to reset password. ' + reason.message || "");
      setLoading(false);
    }));
  }

  //TODO: Set color of error based on ionic colors
  return <IonCard>
    <IonCardHeader><IonCardTitle>Forgot Password</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!!message? <IonText color={'success'}><h3>{message}</h3></IonText>: <></>}
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput type="email" ref={emailRef} placeholder="example: first.last@gmail.com"/>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Reset Password
      </IonButton>
      <p><IonRouterLink routerLink="/login">Login with Password</IonRouterLink></p>
      </form>
      <p>Need an account? <IonRouterLink routerLink="/signup">Sign Up</IonRouterLink></p>
    </IonCardContent>
  </IonCard>
};

export default ForgotPassword;