import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { Redirect, Switch, useLocation } from 'react-router';
import ContactInfo from '../components/ContactInfo';
import DigitalProfile from '../components/DigitalProfile';
import ExploreContainer from '../components/ExploreContainer';
import LogOutButton from '../components/LogOutButton';
import { ForcedChoiceQuestion } from '../components/PresentForcedChoiceQuestion';
import Questionnaire from '../components/Questionnaire';
import SynchDisabledButton from '../components/SynchDisabledButton';
import { useData } from '../services/DataProvider';
import useSubdomain from '../services/SubdomainMapper';
import CircuitTestingSimulator from './CircuitTestingSimulator';
import DistributionEquipmentSimulator from './DistributionEquipmentSimulator';
import DistributionRopes from './DistributionRopes';
import EquipmentLoads from './EquipmentLoads';
import FramingHoles from './FramingHoles';
import './Home.css';
import InspectionSimulator from './InspectionSimulator';
import SolarDCHealth from './SolarDCHealth';
import SolarEquipmentSimulator from './SolarEquipmentSimulator';
import SolarHotspots from './SolarHotspots';
import SolarWiringSimulator from './SolarWiringSimulator';
import TopTieSimulator from './TopTieSimulator';
import TransformerKnots from './TransformerKnots';
import WindDiagramsSimulator from './WindDiagramsSimulator';
import WindDropZoneSimulator from './WindDropZoneSimulator';
import WindEquipmentSimulator from './WindEquipmentSimulator';

type roles = "solartech" | "windtech" | "automation" | "lineman";

const solarQuestions:{[key: string]:ForcedChoiceQuestion} = {
  "powerType": {
    prompt: "Which kind of power do solar panels produce?",
    orderedChoices: ["AC", "DC"]
  }, "neutralGrounding": {
    prompt: "Which of the following is not a kind of neutral grounding?",
    orderedChoices: ["Unearthed Neutral System", "Solid Neutral Earthed System", "Resistance Neutral Earthing System"]
  }
};

const windQuestions:{[key: string]:ForcedChoiceQuestion} = {
  "Nacelle": {
    prompt: "The drive shaft of a wind turbine resides in which part?",
    orderedChoices: ["the tower", "the blade", "the nacelle"]
  }, "neutralGrounding": {
    prompt: "Which of the following is not a kind of neutral grounding?",
    orderedChoices: ["Unearthed Neutral System", "Solid Neutral Earthed System", "Resistance Neutral Earthing System"]
  }
};

const automationQuestions:{[key: string]:ForcedChoiceQuestion} = {
  "capacitanceUnits": {
    prompt: "What is the unit for capacitance?",
    orderedChoices: ["V", "N", "F", "C"]
  }
};

const linemanQuestions: {[key: string]:ForcedChoiceQuestion} = {
  "multiphasePower": {
    prompt: "How many phases do most power lines use?",
    orderedChoices: ["1", "2", "3", "4"]
  },
};

type OnboardingStep = "thanks" | "simulator" | "questionnaire" | "contact" | "role" | "intro" | "synching";

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const Home: React.FC = () => {
  const {profile, setProperty, synching, skillNamesToQuestionIDs, questionIDsToQuestions} = useData();
  const subdomain = useSubdomain();
  const location = useLocation();
  let questions: {[key:string]: ForcedChoiceQuestion} = {};

  if (subdomain === "linemen") {
    profile["role"] ||= "lineman";
  } else if (subdomain === "windtechs") {
    profile["role"] ||= "windtech";
  } else if (subdomain === "solartechs") {
    profile["role"] ||= "solartech";
  }

  let activeStep:OnboardingStep = "thanks"; // TODO: Force type safety by requiring return out of switch statement?
  
  let currentSimulator = null;

  if (!profile?.["introShown"]) {
    activeStep = "intro";
  } else if (!profile?.["role"]) {
    activeStep = "role";
  } else if (!profile?.["contactInfo"]?.["complete"]) {
    activeStep = "contact";
  } else if (!profile?.["questionnaire"]?.["complete"]) {
    activeStep = "questionnaire";
    // TODO: Address case that a specific set of questions are being targeted...
    if (profile?.["role"]==="windtech") {
      questions = windQuestions;
    } else if (profile?.["role"]==="solartech") {
      questions = solarQuestions;
    } else if (profile?.["role"]==="automation") {
      questions = automationQuestions;
    } else if (profile?.["role"]==="lineman") {
      questions = linemanQuestions;
    } else {
      questions = {}; // Leave questions empty if it doesn't match a known role
    }
  } else if (!profile?.["simulator"]?.["complete"]) {
    activeStep = "simulator";
    const solarSimulators:{[key: string]: any} = {
      "solarEquipment": <SolarEquipmentSimulator />,
      "solarHotspots": <SolarHotspots />,
      "solarDCHealth": <SolarDCHealth />,
    };
    const windSimulators:{[key: string]: any} = {
      "windEquipment": <WindEquipmentSimulator />,
      "windDropZone": <WindDropZoneSimulator />,
      "windDiagrams": <WindDiagramsSimulator />,
    };
    const distributionSimulators:{[key: string]: any} = {
      "equipment": <DistributionEquipmentSimulator />,
      "framing": <FramingHoles />,
      "topTie": <TopTieSimulator />,
      "knots": <TransformerKnots />,
      "ropes": <DistributionRopes />,
      "loads": <EquipmentLoads />,
    };
    const automationSimulators:{[key: string]: any} = {
      "circuitTesting": <CircuitTestingSimulator />,
    };

    // TODO: Replace above with arrays to represent the order of the keys (as object implementations are able to adopt other conventions for this unspecified key iteration order)
    // TODO: Also, eventually, somehow share the required simulators between the frontend and backend to prevent shortcutting by marking "simulator" done.

    const simulatorSet:{[key in roles]:{[key: string]: any}} = {
      "solartech": solarSimulators,
      "windtech": windSimulators,
      "lineman": distributionSimulators,
      "automation": automationSimulators,
    };

    const simulators = simulatorSet[profile?.["role"] as roles] || {} as {[key: string]: any};

    let simName: keyof typeof simulators;
    let simulatorAssigned = false;
    for (simName in simulators) {
      if (!profile?.[simName]?.["complete"]) {
        currentSimulator = simulators[simName];
        simulatorAssigned = true;
        break;
      }
    }
    if (!simulatorAssigned) {
        // profile.setProperty("simulator", {complete: true}); //Throws an error.
        activeStep = "thanks";
        profile["simulator"] ||= {}
        profile["simulator"]["complete"] = true; // Doesn't trigger update of state, so doesn't get graded until the next update, necessitating the following lines.
        profile["completeDictionary"] ||= {};
        profile["completeDictionary"]["simulator"] = true;
        profile["fractionComplete"] = 1/Object.keys(profile["completeDictionary"]).length;
    }

    // if (profile?.["role"]==="lineman") {
    //   if (profile?.["equipment"]?.["complete"]) {
    //     distributionSimulator = "framing";
    //   }
    //   if (profile?.["framing"]?.["complete"]) {
    //     distributionSimulator = "topTie";
    //   }
    //   if (profile?.["topTie"]?.["complete"]) {
    //     distributionSimulator = "knots";
    //   }
    //   if (profile?.["knots"]?.["complete"]) {
    //     distributionSimulator = "ropes";
    //   }
    //   if (profile?.["ropes"]?.["complete"]) {
    //     distributionSimulator = "loads";
    //   }
    //   if (profile?.["loads"]?.["complete"]) {
    //     // profile.setProperty("simulator", {complete: true}); //Throws an error.
    //     activeStep = "thanks";
    //     profile["simulator"] ||= {}
    //     profile["simulator"]["complete"] = true; // Doesn't trigger update of state, so doesn't get graded until the next update, necessitating the following lines.
    //     profile["completeDictionary"] ||= {};
    //     profile["completeDictionary"]["simulator"] = true;
    //     profile["fractionComplete"] = 1/Object.keys(profile["completeDictionary"]).length;
    //   }
    // }
  }
  // if (synching) {
  //   activeStep = "synching"
  // }

  // Check to see if there are active questions (additional subsection)
  let extraQuestionsActive = false;
  if (activeStep === "thanks") {
    const skillName = profile?.activeQuestions;
    if (skillName && !profile?.completeDictionary?.["skillName"]) {
      extraQuestionsActive = true;
      const questionIDs = skillNamesToQuestionIDs[skillName];
      questions = {};
      questionIDs.forEach((questionID, index)=>{
        const question = questionIDsToQuestions[questionID];
        if (question.question.QUESTION_TYPE === "MCQ") {
          questions[questionID] = {
            prompt: question.question.prompt,
            orderedChoices: shuffleArray([question.question.correctAnswer, ...question.question.distractors]),
          }
        }
      });
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Power Techs - Jobs</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Power Techs - JOBS</IonTitle>
          </IonToolbar>
        </IonHeader>
{/* 
        {activeStep==="synching"? 
          <IonCard>
            <IonCardHeader><IonCardTitle>Please wait...</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <p>Your data is being processed...</p>
            </IonCardContent>
          </IonCard>
        :<></>} */}

        {activeStep==="intro"? 
          <IonCard>
            <IonCardHeader><IonCardTitle>Get a high-paying technician role in green energy!</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <p>Thanks for your interest in PowerTechs.US, the platform that connects you with your future employer in the energy sector. In the next 10 minutes, you're going to fill out some basic information, answer a few job-related questions, and complete a short simulator-based task. If you score highly enough, we'll share your profile with our partnering companies.</p>
              <SynchDisabledButton onClick={()=>{setProperty("introShown", true)}}>Get started!</SynchDisabledButton>
            </IonCardContent>
          </IonCard>
        :<></>}

        {activeStep==="role"? 
          <IonCard>
            <IonCardHeader><IonCardTitle>What kind of roles are you interested in?</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <SynchDisabledButton onClick={()=>{setProperty("role", "windtech")}}>Wind Technician</SynchDisabledButton>
              <SynchDisabledButton onClick={()=>{setProperty("role", "solartech")}}>Solar Technician</SynchDisabledButton>
              {/* <IonButton onClick={()=>{setProperty("role", "automation")}}>Instrumentation & Control Technician</IonButton> */}
              <SynchDisabledButton onClick={()=>{setProperty("role", "lineman")}}>Distribution Lineworker</SynchDisabledButton>
            </IonCardContent>
          </IonCard>
        :<></>}

        {activeStep==="contact"? 
          <ContactInfo />
        :<></>}

        {activeStep==="questionnaire"? 
          <IonCard>
            <IonCardHeader><IonCardTitle>Knowledge Assessment?</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <Questionnaire questions={questions}/>
            </IonCardContent>
          </IonCard>
        :<></>}

        {activeStep==="simulator"? <>
          {/* {profile?.["role"]==="windtech"? <InspectionSimulator /> : <></> }
          {profile?.["role"]==="solartech"? <SolarWiringSimulator /> : <></> }
          {profile?.["role"]==="automation"? <CircuitTestingSimulator /> : <></> }
          {profile?.["role"]==="lineman"? <>
            {distributionSimulator==="equipment"? <DistributionEquipmentSimulator/> :<></>}
            {distributionSimulator==="framing"? <FramingHoles />: <></>}
            {distributionSimulator==="topTie"? <TopTieSimulator/> :<></>}
            {distributionSimulator==="knots"? <TransformerKnots/> :<></>}
            {distributionSimulator==="ropes"? <DistributionRopes/> :<></>}
            {distributionSimulator==="loads"? <EquipmentLoads/> :<></>}
            </>: <></> } */}
          {currentSimulator}
        </> : <></>}

        {activeStep==="thanks"? <>
          {location.pathname==="/home" && (!profile?.allComplete) && <Redirect to="/portfolio" />}
          {["/portfolio", "/home"].includes(location.pathname) && (profile?.allComplete) && <Redirect to="/complete" />}
          {profile?.allComplete && <IonCard>
            <IonCardHeader><IonCardTitle>Your application has been submitted!</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <p>Thanks for completing your application! Your profile is now available for review by employers, and many of our candidates hear back within several weeks.</p>
              <p>Feel free to continue adding to your digital portfolio below.</p>
            </IonCardContent>
          </IonCard>
          }
          {extraQuestionsActive && !synching? <Questionnaire questions={questions}/>: <DigitalProfile/>}
        </>
        : location.pathname!=="/home" && <Redirect to="/home"/>}
        
        <IonCard>
          <IonCardContent>
            <IonButton routerLink='/update-profile' color="light">Change email/password</IonButton>
            <p><LogOutButton /></p>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
