import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../App";

const AdminListUsers: React.FC = () => {
  const serverAdminFetchLogins = httpsCallable(functions, "adminFetchLogins");
  const serverFetchHRRecords = httpsCallable(functions, "fetchHRRecords");
  const [logins, setLogins] = useState(null as any);
  const [hrRecords, setHRRecords] = useState(null as any);

  useEffect(()=>{
    // TODO: Call serverAdminFetchLogins and update the state variable.
    serverAdminFetchLogins({}).then((result)=>{ // Note: currently only does the first 1000
      setLogins((result.data as {"users": any[]}).users || []);
    });

    serverFetchHRRecords({collectionName: "profiles"}).then((result)=>{
      setHRRecords(result.data);
    });
  }, []);

  const validatedLinemenEmails: string[] = [];
  if (logins && hrRecords) {
    hrRecords.forEach((record: {owner: string, role: string, startTime: number|undefined})=>{
      if (record.role !== "lineman") {
        return; // Shortcut if it is not a lineman
      }
      if (!record.startTime || !(record.startTime > new Date("2022-11-12").getTime())) {
        return; // Shortcut if it is not a lineman from the time of competition launch.
      }
      const id = record.owner;
      const matchingLogins = logins.filter((login:{uid:string})=>{return login.uid===id});
      if (matchingLogins.length <= 0) {
        validatedLinemenEmails.push("Couldn't find email for UID: " + id);
      } else {
        validatedLinemenEmails.push(matchingLogins.map((login: {email: string, uid: string})=>{return login.email + " " + login.uid}).join(", "));
      }
    })
  }

  return <IonCard>
    <IonCardHeader><IonCardTitle>List of Linemen Users:</IonCardTitle></IonCardHeader>
    <IonCardContent>
      <p>Number of Validated Users: {validatedLinemenEmails.length}</p>
      <br/>

      {validatedLinemenEmails.sort().map((email, index)=>{
        return <p key={index}>{email}</p>
      })}

      {/* <br/>

      {JSON.stringify(logins?.[0])}

      <br/>

      {JSON.stringify(hrRecords?.[0])} */}
    </IonCardContent>
  </IonCard>
}

export default AdminListUsers