import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import { useAuth } from "../services/AuthProvider";
import { useData } from "../services/DataProvider";
import SynchDisabledButton from "./SynchDisabledButton";

const ContactInfo: React.FC = () => {
  const {profile, setProperty} = useData();
  const {currentUser} = useAuth();
  const propertyName = "contactInfo";
  const [contactInfo, setContactInfo] = useState(profile[propertyName] || {} as any);

  const contactComplete = contactInfo["givenName"] && contactInfo["surname"] && contactInfo["phone"] && true; // Last value converts to boolean.
  const contactStarted = contactInfo["givenName"] || contactInfo["surname"] || contactInfo["phone"] || false;

  return (
    <IonCard>
      <IonCardHeader><IonCardTitle color={contactComplete? "success" : (contactStarted? "warning" : "dark")}>Contact Info</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <IonGrid><IonRow>
          <IonCol size="12" sizeMd="6" sizeLg="3">
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput type="text" placeholder="My Name" value={contactInfo["givenName"]||""} onInput={(event)=>{contactInfo["givenName"]= event.currentTarget.value; setContactInfo({...contactInfo});}}/>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="6" sizeLg="3">
            <IonItem>
              <IonLabel position="floating">Surname</IonLabel>
              <IonInput type="text" placeholder="Family Name" value={contactInfo["surname"]||""} onInput={(event)=>{contactInfo["surname"]= event.currentTarget.value; setContactInfo({...contactInfo});}}/>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="6" sizeLg="3">
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput type="email" disabled={true} placeholder="your.name@domain.com" value={currentUser?.email || contactInfo["email"] || ""} onInput={(event)=>{contactInfo["email"]= event.currentTarget.value; setContactInfo({...contactInfo});}}/>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="6" sizeLg="3">
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <IonInput type="tel" placeholder="(555)555-5555" value={contactInfo["phone"]||""} onInput={(event)=>{contactInfo["phone"]= event.currentTarget.value; setContactInfo({...contactInfo});}}/>
            </IonItem>
          </IonCol>
        </IonRow></IonGrid>
        <SynchDisabledButton disabled={!contactComplete} onClick={()=>{
          if(contactComplete){
            contactInfo["complete"]= contactComplete; setProperty(propertyName, contactInfo);
          }
          }}>Proceed
        </SynchDisabledButton>
      </IonCardContent>
    </IonCard>
  );
};

export default ContactInfo;