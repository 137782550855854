import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonText } from "@ionic/react";

const Page404: React.FC = ({...props}) => {
  
  return <IonPage>
    <IonContent style={{"--background":"url('/assets/splash/WindAndSolar.png') no-repeat center center / cover"}}>
      <IonCard>
        {/* <img src="/assets/logos/PowerTechsLogoColor.svg" style={{margin:"1em", height:"4em", float:"right"}}/> */}
        {/* <img src="/assets/logos/PowerTechsIcon.svg" style={{height:"1.2em", position:"relative", top:".23em"}}/> */}
        <img src="/assets/logos/PowerTechsIcon.svg" style={{height:"4em", margin:"1em", float:"right"}}/>
        <IonCardHeader>
          <IonCardTitle>Code 404: Page not Found</IonCardTitle>
          <IonText color="dark"><h5>Hm... Where could it be?</h5></IonText>
        </IonCardHeader>
        
        <IonCardContent>
          <p>We couldn't find the page you were looking for. If you followed a link to this page, that's probably our mistake. Sorry about that!</p>
          <p>If you typed in the web address, did you check the spelling?</p>
          <IonButton routerLink="/">Go to the Home Page</IonButton>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>
};

export default Page404;