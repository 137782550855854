import { IonCard, IonCardContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { useData } from "../services/DataProvider";
import PresentForcedChoiceQuestion, { ForcedChoiceQuestion } from "./PresentForcedChoiceQuestion";

const Questionnaire:React.FC<{questions: {[key: string]:ForcedChoiceQuestion} }> = ({questions, ...props})=>{
  const {profile, setProperty} = useData();
  const [responses, setResponses] = useState(profile?.["questionnaire"] || {} as any)

  // TODO: Shuffling of responses and/or question order?

  let activeQuestion = null as string|null;

  for (const key in questions) {
    if (! (key in responses)) {
      activeQuestion = key;
    }
  }

  useEffect(()=>{
    if (!activeQuestion) {
      responses["complete"] = true;
      // In order to prevent an infinite server-call loop, the first modification is on the live profile object.
      profile["activeQuestions"] = null;
      setProperty("questionnaire", responses);
    }
  })

  return <IonCard><IonCardContent>
    {activeQuestion? <PresentForcedChoiceQuestion question={questions?.[activeQuestion]} answerCallback={(index)=>{responses[activeQuestion || ""] = questions[activeQuestion || ""].orderedChoices[index]; setResponses({...responses});}}/> : <></>}
  </IonCardContent></IonCard>
}

export default Questionnaire;