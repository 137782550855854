import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import HRHome from './pages/HRHome';
import AppRouter from './AppRouter';
import DataProvider, { TempDataProvider } from './services/DataProvider';

import { initializeApp } from "firebase/app";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import firebaseConfig from './firebaseConfig';
import AuthProvider from './services/AuthProvider';
import useSubdomain from './services/SubdomainMapper';

setupIonicReact();

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
if (window.location.hostname.toLowerCase() == "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); //TODO: Remove
}
export {firebaseApp, functions}//, firebaseFunctions}; // Since it's pretty unimaginable to be running two firebase APIs in one frontend, this wil just be a global export, not a context provider.
// However, uses of the object (auth, firestore) will have related context providers.

const App: React.FC = () => {
  const subdomain = useSubdomain();

  return <IonApp>
    <AuthProvider>
      {["demo", "solar-demo", "wind-demo"].includes(subdomain)?
        <TempDataProvider>
          <AppRouter/>
        </TempDataProvider>
      :
        <DataProvider>
          <AppRouter/>
        </DataProvider>
      }
    </AuthProvider>
  </IonApp>
};

export default App;
