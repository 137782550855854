import { IonButton, IonCard, IonCardContent, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRouterLink, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { checkmarkCircle, recording } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import { useData } from '../services/DataProvider';
import './Home.css';

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
// https://stackoverflow.com/questions/42136098/array-groupby-in-typescript

const nameCase = (name: string) => {
  if (!name) {
    return "";
  }
  const parts = name.split(" ");
  return parts.map((part)=>{
    return part? part[0].toUpperCase() + part.slice(1).toLowerCase() : part;
  }).join(" ")
}

const toPercentage = (fraction:number, precisionDigits=2)=>{
  return `${((fraction || 0)*100).toFixed(precisionDigits)}%`
};

function titleCase(str:string) {
  let newStr = str.toLowerCase().split(' ');
  for (var i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].charAt(0).toUpperCase() + newStr[i].slice(1); 
  }
  return newStr.join(' ');
}

const Leaderboard: React.FC = () => {
  const [hrRecords, setHRRecords] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  const {serverFetchLeaderboardData, profile} = useData();
  const selectedRole = profile?.["role"] || "lineman"; // TODO: Differentiate based on subdomain.

  useEffect(()=>{
    setLoading(true);

    serverFetchLeaderboardData({}).then((result)=>{
      setHRRecords(result.data);
      setLoading(false);
    })

  }, []); // Only run once.

  const roleGroups = groupBy(hrRecords, (record)=>{
    return record?.["role"];
  }) || {}

  // const roles = Object.keys(roleGroups).filter((roleName)=>{return roleName && roleName!=="undefined"}); // Not sure how "undefined" gets introduced into the records.
  // if (!selectedRole && roles.length > 0) {
  //   selectedRole = roles[0];
  // }

  const roleRecords = roleGroups[selectedRole] || [];

  // TODO: Grade roleRecords
  // const roleGrades = roleRecords.map((record)=>{
  //   let technicalAvailable = 0;
  //   let technicalEarned = 0;
  //   const questionnaireResponses = record["questionnaire"] || {};
  //   for (let questionName in questionnaireResponses) {
  //     if (questionName === "complete") {
  //       // Skip; just marking completion
  //     } else if (questionName === "ohmsLaw") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "V"? 1 : 0;
  //     } else if (questionName === "powerType") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "DC"? 1 : 0;
  //     } else if (questionName === "neutralGrounding") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "Unearthed Neutral System"? 1 : 0;
  //     } else if (questionName === "Nacelle") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "the nacelle"? 1 : 0;
  //     } else if (questionName === "capacitanceUnits") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "F"? 1 : 0;
  //     } else if (questionName === "multiphasePower") {
  //       technicalAvailable += 1;
  //       technicalEarned += questionnaireResponses[questionName] === "3"? 1 : 0;
  //     }
  //   }
  //   technicalAvailable ||= 1; // Set to 1 if still 0.
  //   let technicalScore = technicalEarned/technicalAvailable;

  //   let safetyAvailable = 0;
  //   let safetyEarned = 0;
  //   safetyEarned += safetyAvailable===0? 1 : 0;
  //   safetyAvailable ||= 1;
  //   let safetyScore = safetyEarned/safetyAvailable;

  //   const finalMatch = (technicalScore + safetyScore)/2;

  //   return {contactInfo:record.contactInfo, match: finalMatch, technicalKnowledge: technicalScore, safetyCompliance: safetyScore, teamWork: Math.random()};
  // }).sort((record1, record2)=>{return record2.match - record1.match}); // Sort in descending order.

  const roleGrades = roleRecords.map((record)=>{
    return record;
  }).sort((record1, record2)=>{
    if(!record1.fractionComplete || !record1.matchFractions["match"]) {
      return 1;
    } else if (!record2.fractionComplete || !record2.matchFractions["match"]) {
      return -1;
    }
    if(record1.fractionComplete !== record2.fractionComplete) { // Sort in decreasing order.
      return record2.fractionComplete - record1.fractionComplete;
    } else {
      return record2.matchFractions["match"] - record1.matchFractions["match"]
    }
  }).filter((record)=>{
    //return record.fractionComplete && record.fractionComplete >= 1;
    return record.matchFractions?.["match"];
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>PowerTechs - {titleCase(selectedRole)} Leaderboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">PowerTechs - {titleCase(selectedRole)} Leaderboard</IonTitle> {/* This is what shows on mobile. */}
          </IonToolbar>
        </IonHeader>

        <IonCard style={{maxWidth: "initial", overflowX: "auto"}}>
          <IonCardContent>
            {/* {roles.length > 1? <>
              <h1 style={{textAlign:"center"}}>Select Role</h1>
              <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow>
                {roles.map((role, index)=>{
                  return <IonCol key={index}><IonButton color={role===selectedRole? "primary" : "secondary"} onClick={()=>{setSelectedRole(role)}}>{role}</IonButton></IonCol>
                })}
              </IonRow></IonGrid>
            </> 
            : <></>} */}
            {loading? <IonSpinner style={{display:"block", margin:"auto"}} /> : <></>}

            {/* {roleRecords?.[0]? JSON.stringify(roleRecords[0]): <></>} */}
            <table style={{margin:"auto"}}>
            <thead>
              <tr><td>Ranking</td><td>ID {/*(click to view profile)*/}</td><td>Score</td><td>Technical Knowledge</td><td>Safety Compliance</td><td>Additional Factors</td></tr>
              </thead>
              <tbody>
              {/* <IonText color="success">✓</IonText> */}
              {roleGrades.map((record, index)=>{
                if (record?.matchFractions?.["match"]) {
                  return <tr key={index}><td>{index + 1}</td><td title={record.id}>{(profile.id === record.id)&&<IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>}{nameCase(record?.contactInfo?.["givenName"] || "id not found")}</td><td><IonText color="success">{toPercentage(record.matchFractions["match"])}</IonText></td><td><IonText>{toPercentage(record.matchFractions["technical"])}</IonText></td><td><IonText>{toPercentage(record.matchFractions["safety"])}</IonText></td><td>{toPercentage(record.matchFractions["employability"])}</td></tr>
                } else {
                  return <tr key={index}><td>{index + 1}</td><td>{(profile?.id === record.id)&&<IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>}{nameCase(record?.contactInfo?.["givenName"] || "id not found")}</td><td>~</td><td>~</td><td>~</td><td>~</td></tr>
                }

              })}
            </tbody>
            </table>
            
          </IonCardContent>
        </IonCard>

        {profile?.id? 
        <IonCard>
          <IonCardContent>
            <h1><IonRouterLink href="/home">Return Home</IonRouterLink></h1>
          </IonCardContent>
        </IonCard>
        : null}
      </IonContent>
    </IonPage>
  );
};

export default Leaderboard;