import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import SynchDisabledButton from "../components/SynchDisabledButton";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";

import { packSquares } from "./SolarEquipmentSimulator";

export type Rectangle = [number, number, number, number]; // x, y, width, height.
export type position = Rectangle; // TODO: Refactor to only use Rectangle type.

const WindEquipmentSimulator: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  let intervalHandleInit = null as NodeJS.Timeout|null;
  let intervalHandle = useRef(intervalHandleInit);

  const {width, height} = useWindowDimensions(); //Note: Uses a custom-width card to achieve this.

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  const fractionToRealPositions = ([fractionX, fractionY, fractionWidth, fractionHeight]:position):position => {
    return [fractionX * canvasWidth, fractionY * canvasHeight, fractionWidth * canvasWidth, fractionHeight * canvasHeight];
  };

  const realToFractionPositions = ([realX, realY, realWidth, realHeight]:position):position => {
    return [realX / canvasWidth, realY / canvasHeight, realWidth / canvasWidth, realHeight / canvasHeight];
  };

  const tools = {
    "hammer":{src:"./assets/tasks/ElectricalEquipment/SourceImages/Linemans-Hammer.jpg"},
    "skinning knife":{src: "./assets/tasks/ElectricalEquipment/SourceImages/Buckingham-Skinning-Knife.jpg"},
    "demolition screwdriver":{src: "./assets/tasks/ElectricalEquipment/SourceImages/Demolition-Driver.jpg"},
    "cutting pliers":{src: "./assets/tasks/ElectricalEquipment/SourceImages/Linemans-Pliers.jpg"},
    "pump pliers":{src: "./assets/tasks/ElectricalEquipment/SourceImages/Pump-Pliers.jpg"},
    "electrical tape":{src: "./assets/tasks/ElectricalEquipment/SourceImages/Electrical-Tape_Altec-Connect.jpg"},
    "multimeter":{src:"./assets/tasks/ElectricalEquipment/SourceImages/ClampMultimeter.png"},
    "liftingBag": {src: "./assets/tasks/WindEquipment/SourceImages/liftingBag.jpg"},
    "headlamp": {src: "./assets/tasks/WindEquipment/SourceImages/headlamp.jpg"},
    "hardhat":{src:"./assets/tasks/ElectricalEquipment/SourceImages/hardhat.jpg"},
    "vest": {src: "./assets/tasks/WindEquipment/SourceImages/vest.jpg"},
    "sleeves":{src:"./assets/tasks/ElectricalEquipment/SourceImages/sleeves.jpg"},
    "gloves":{src:"./assets/tasks/ElectricalEquipment/SourceImages/gloves.jpg"},
    "wire":{src:"./assets/tasks/ElectricalEquipment/SourceImages/wire.jpg"},
    "vise grip":{src:"./assets/tasks/ElectricalEquipment/SourceImages/vise grip.jpg"},
    "steel screwdriver":{src:"./assets/tasks/ElectricalEquipment/SourceImages/steel screwdriver.webp"},
    "umbrella":{src:"./assets/tasks/ElectricalEquipment/SourceImages/umbrella.jpg"},
    "inductor":{src:"./assets/tasks/ElectricalEquipment/SourceImages/inductor.jpg"},
    "lifeline": {src: "./assets/tasks/SolarEquipment/SourceImages/SolarLifelineKit.png"},
    "multifunctionTool": {src: "./assets/tasks/SolarEquipment/SourceImages/MultifunctionTool.jpg"},
    "radio": {src: "./assets/tasks/WindEquipment/SourceImages/radio.jpg"},
  };
  // Correct tools include: Claw-Mill Hammer, Pump-Pliers, Cutting Pliers, Skinning Knife, Demolition Screwdriver, Electrical Tape, sleeves, gloves, hard hat
  // Incorrect tools include: multimeter, vise grip, steel screwdriver, umbrella
  // Wire ungraded.

  const imagesInit = Object.fromEntries(Object.entries(tools).map(([key, value])=>{
    return [key, new Image()]
  })) as Record<keyof typeof tools, HTMLImageElement>;

  const instancesInit: {name:keyof typeof tools, position: position, active:boolean, mouseover:boolean, weight: number, caption?:string}[] = [
    {name: "vise grip", position: [0.02, .1, .18, .27], active: false, mouseover: false, weight: .7},
    {name: "pump pliers", position: [0.02, .4, .18, .27], active: false, mouseover: false, weight: .5},
    {name: "cutting pliers", position: [0.02, .7, .18, .27], active: false, mouseover: false, weight: .5},
    {name: "demolition screwdriver", position: [.2, .1, .2, .27], active: false, mouseover: false, weight: .5},
    {name: "steel screwdriver", position: [.2, .4, .2, .27], active: false, mouseover: false, weight: 1.1},
    {name: "electrical tape", position: [.2, .7, .2, .27], active: false, mouseover: false, weight: 1},
    {name: "hardhat", position: [.4, .1, .2, .27], active: false, mouseover: false, weight: 1.4},
    {name: "headlamp", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: .3},
    {name: "vest", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: .5},
    {name: "sleeves", position: [.4, .4, .2, .27], active: false, mouseover: false, weight: 3},
    {name: "gloves", position: [.4, .7, .2, .27], active: false, mouseover: false, weight: 1.3},
    {name: "lifeline", position: [0, 0, 0, 0], active: false, mouseover: false, weight: 2},
    {name: "multimeter", position: [.6, .1, .2, .27], active: false, mouseover: false, weight: 1},
    {name: "radio", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: 2.1},
    {name: "wire", position: [.6, .4, .2, .27], active: false, mouseover: false, weight: 1},
    {name: "inductor", position: [.6, .7, .2, .27], active: false, mouseover: false, weight: .1},
    {name: "skinning knife", position: [.8, .1, .2, .27], active: false, mouseover: false, weight: .25},
    {name: "umbrella", position: [.8, .4, .2, .27], active: false, mouseover: false, weight: 2},
    {name: "hammer", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: 1},
    {name: "multifunctionTool", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: 1},
    {name: "liftingBag", position: [.8, .7, .2, .27], active: false, mouseover: false, weight: 5},
  ];

  // Set the positions based on the total number of items.
  const totalItems = instancesInit.length;
  const newPositions = packSquares([0, 0, canvasWidth, canvasHeight], totalItems, .1);
  instancesInit.forEach((instance, index)=>{
    instance.position = realToFractionPositions(newPositions[index]);
  })

  let mousePositionXInit = 0;
  let mousePositionYInit = 0;

  // https://reactjs.org/docs/hooks-reference.html#useref
  // Ref for mutable state to persist across window resize.
  const simulatorState = useRef({ // Simulator State.
    tools: tools,
    images: imagesInit,
    instances: instancesInit,
    mousePositionX: mousePositionXInit,
    mousePositionY: mousePositionYInit,
  }); // TODO: Move images to a useMemo or something? Shouldn't wind up in the database-storable state. Currently images are reloaded every time the simulator reloads. Consider making a separate useRef or something and changing useEffect to not reload if they are already loaded.
  const state = simulatorState.current; // Shortform reference to the mutable object.

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth); // Fraction 0-1
    state.mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight); // Fraction 0-1

    state.instances.forEach((instance, index)=>{
      if (checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
        instance.mouseover = true; // This updates the original object.
      } else {
        instance.mouseover = false;
      }
    });
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:position) => {
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  // Both of these assume square coordinate systems (pixel space, not fraction space).
  const containFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the bigger of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const biggerRatio = Math.max(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / biggerRatio;
    const chosenHeight = imageHeight / biggerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const coverFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the smaller of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const smallerRatio = Math.min(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / smallerRatio;
    const chosenHeight = imageHeight / smallerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const [weightReading, setWeightReading] = useState(0);

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.instances.forEach((instance, index)=>{
      if (checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
        instance.active = ! instance.active; // This updates the original object.
      }
    });
    
    const newWeightReading = state.instances.reduce((cumulative, current)=>{
      if (current.active) {
        cumulative += current.weight;
      }
      return cumulative;
    }, 0);
    setWeightReading(newWeightReading);
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw image instances in order.
    state.instances.forEach((item)=>{
      const itemImage = state.images[item.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(item.position);
      const fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);

      if (item.active) {
        const [fitX, fitY, fitWidth, fitHeight] = fitPosition;
        const border = 5; // px.
        context.fillStyle = "#999999";
        context.fillRect(fitX - border, fitY - border, fitWidth + 2*border, fitHeight + 2*border);
      }

      if (item.mouseover) {
        context.globalAlpha = .8;
      }
      context.drawImage(itemImage, ...fitPosition);
      if (item.caption) {
        const captionHeight = canvasHeight/25; // Arbitrary. Still looks okay on iPhone.
        context.font = `${captionHeight}px sans serif`;
        const measure = context.measureText(item.caption);
        // Place Caption Background
        context.globalAlpha = .5;
        context.fillStyle = "#FFFFFF";
        context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3] - captionHeight, measure.width, captionHeight * 1.5); // Scale by 1.5 to cover below letters too.
        //context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3], 100, 100);
        context.globalAlpha = 1;
        // Place Caption
        context.strokeStyle = "#FFFFFF";
        context.strokeText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
        context.fillStyle = "#000000";
        context.fillText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
      }
      context.globalAlpha = 1;

      // Debugging only to adjust windows on dev screen:
      const debugging = false;
      if (window.location.hostname.toLowerCase()==="localhost" && debugging) { // can be turned on and off.
        context.strokeStyle = "#00FF00";
        context.strokeRect(...targetPosition);
        context.strokeStyle = "#0000FF";
        context.strokeRect(...fitPosition);
      }
    })

    // const weightReading = state.instances.reduce((cumulative, current)=>{
    //   if (current.active) {
    //     cumulative += current.weight;
    //   }
    //   return cumulative;
    // }, 0);
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      // `Selected Equipment Weight: ${weightReading.toFixed(1)} kg`,
      // `Mouse Position X: ${mousePositionX.toFixed(2)}`,
      // `Mouse Position Y: ${mousePositionY.toFixed(2)}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  }

  const drawLoaded = (numerator:number, denominator:number) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      `Simulator Components Loaded: ${numerator}/${denominator}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  };

  useEffect(() => {
    var loaded = 0; // Counts up to the number of items; note: images are reloaded (I think) every render.
    const onload = () => {
      loaded = loaded + 1;
      if (loaded < Object.keys(state.images).length) {
        drawLoaded(loaded, Object.keys(state.images).length); // Draw Loading Update.
        return;
      }

      draw();

      if (!intervalHandle.current) {
        intervalHandle.current = setInterval(draw, 1000/24);
      }
    }

    let key: keyof typeof tools;
    for (key in state.images) {
      state.images[key].onload = onload
      state.images[key].src = tools[key].src;
    } // This initiates (I think) the reload of images, even if the src is the same.

    return (()=>{
      if (intervalHandle.current) {
        clearInterval(intervalHandle.current);
        intervalHandle.current = null;
      }
    })
  }); //[]

  const promptText = "You are going into the field to perform some routine maintenance and inspection on a wind turbine. What do you take with you?";

  return (
    // TODO: Add wrapping page for demo purposes?
    // TODO: Add a note about using landscape mode...
    <IonCard style={{maxWidth: "initial"}}>
      <IonCardContent>
        {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}

        <br/>
        {isMobile? <p>Selected Equipment Weight: {weightReading.toFixed(1)} kg</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>Selected Equipment Weight: {weightReading.toFixed(1)} kg</h1>}
        <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick}/>

        <SynchDisabledButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("windEquipment", {complete: true, state: state})}}>Submit</SynchDisabledButton>
        {/* TODO: Move submit button to a wrapper element? */}

        {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
        {width < height? <IonText color="danger"><p>For best performance, consider using landscape mode.</p></IonText> : <></>}
      </IonCardContent>
    </IonCard>
  );
};

export default WindEquipmentSimulator;