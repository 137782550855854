import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import SynchDisabledButton from "../components/SynchDisabledButton";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";
import { position } from "./DistributionEquipmentSimulator";

const TopTieSimulator: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {width, height} = useWindowDimensions(); //Note: Uses a custom-width card to achieve this.

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const heightUnits = 2; // 2 meter(s).
  const widthUnits = aspectRatio * heightUnits;
  const unitsToPixels = canvasWidth / widthUnits;

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  let mousePositionXInit = 0;
  let mousePositionYInit = 0;

  // TODO: Add support for half-buttons with over-/under- -wire properties?
  type actions = "top"|"bottom"|"button"|"fan out"|"flatten"|"weld";
  type actionsUndo = actions | "undo";
  const actionsStackInit: ("top"|"bottom"|"button"|"fan out"|"flatten"|"weld")[] = [
    // "top",
    // "button",
    // "bottom",
    // "button",
    // "button",
    // "button",
    // "top",
    // "button",
    // "button",
    // "fan out",
    // "weld"
  ];

  // https://reactjs.org/docs/hooks-reference.html#useref
  // Ref for mutable state to persist across window resize.
  const simulatorState = useRef({ // Simulator State.
    mousePositionX: mousePositionXInit,
    mousePositionY: mousePositionYInit,
  });
  const state = simulatorState.current; // Shortform reference to the mutable object.
  const [actionsStack, setActionsStack] = useState(actionsStackInit);
  const updateActions = () => {
    setActionsStack([... actionsStack]);
  }

  // Build state from stack.
  let currentRadius = 0;
  let currentSide: "top"|"bottom" = "bottom";
  const allArcs: [number, number, "top"|"bottom"][] = [];
  const allButtons: [number, number, "top"|"bottom"][] = [];
  let ended = false;
  let welded = false;
  actionsStack.forEach((action, index)=>{
    if (ended) {
      return; // skip these cases.
    }

    if ((index === 0) && ((action === "top") || (action === "bottom"))) {
      allArcs.push([currentRadius, currentRadius, action]);
      currentSide = action;
    } else if (action === "top" || action === "bottom") {
      allArcs.push([currentRadius, currentRadius + 1, action]);
      currentRadius += 1;
      currentSide = action;
    } else if (action === "button") {
      allButtons.push([currentRadius, currentRadius + 1, currentSide]);
      currentRadius += 1;
    } else if (action === "fan out") {
      const fanRadius = 5;
      allButtons.push([currentRadius, currentRadius + fanRadius, currentSide]);
      currentRadius += fanRadius;
      allButtons.push([currentRadius, currentRadius + fanRadius, currentSide]);
      currentRadius += fanRadius;
    } else if (action === "flatten") {
      ended = true;
    } else if (action === "weld") {
      welded = true;
      ended = true;
    } else { // Check that all cases have been covered.
      // https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
      let error:never = action;
    }
  });

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth); // Fraction 0-1
    state.mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight); // Fraction 0-1
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:position) => {
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  const fractionToRealPositions = ([fractionX, fractionY, fractionWidth, fractionHeight]:position):position => {
    return [fractionX * canvasWidth, fractionY * canvasHeight, fractionWidth * canvasWidth, fractionHeight * canvasHeight];
  };

  // Both of these assume square coordinate systems (pixel space, not fraction space).
  const containFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the bigger of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const biggerRatio = Math.max(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / biggerRatio;
    const chosenHeight = imageHeight / biggerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const coverFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the smaller of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const smallerRatio = Math.min(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / smallerRatio;
    const chosenHeight = imageHeight / smallerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    //Draw the central Pole, Crossbar, Insulator, and line. (Unmoving)
    context.fillStyle = "#5c4430";
    const centerX = canvasWidth/2;
    const crossbarWidthUnits = .1 // A tenth of a meter.
    const crossbarWidthPixels = crossbarWidthUnits * unitsToPixels;
    context.fillRect(centerX - crossbarWidthPixels/2, 0, crossbarWidthPixels, canvasHeight * 2/3);

    context.fillStyle = "#4d1a06";
    context.beginPath();
    const radiusUnits = .17; // Estimating at 1/6 of a meter.
    const poleRadiusPixels = radiusUnits * unitsToPixels;
    context.arc(canvasWidth / 2 + poleRadiusPixels + crossbarWidthPixels / 2, 0, poleRadiusPixels, 0, Math.PI * 2, true);
    context.fill();


    const insulatorRadiusUnits = .12; // Estimating.
    const insulatorRadiusPixels = insulatorRadiusUnits * unitsToPixels;

    const wireWidthUnits = .01;
    const wireWidthPixels = Math.max(wireWidthUnits * unitsToPixels, 10);

    // --- Add Top/Bottom Wire illustrations
    const tieWidth = wireWidthPixels/2;
    context.lineWidth = tieWidth + 2; // Draw background;
    //const grad= context.createLinearGradient(canvasWidth/2 - 2 * insulatorRadiusPixels, canvasHeight/2 - 2 * insulatorRadiusPixels, canvasWidth/2 + 2 * insulatorRadiusPixels, canvasHeight/2 + 2 * insulatorRadiusPixels);
    const grad = context.createRadialGradient(canvasWidth/2, canvasHeight/2, 0, canvasWidth/2, canvasHeight/2, 3 * insulatorRadiusPixels);
    grad.addColorStop(0, "#ffffff");
    grad.addColorStop(1, "#aaaaaa");

    // const topArcs = [0,3,4,5];
    // const bottomArcs = [1,2];

    // const allArcs: [number, number, "top"|"bottom"][] = [
    //   [0, 0, "top"], // A bit of a cheat. Draws two, but only one is really there, and they cover each other.
    //   [1, 2, "bottom"],
    //   [5, 6, "top"],
    // ];

    allArcs.forEach(([startIndex, endIndex, side])=>{
      const startRadius = insulatorRadiusPixels + (tieWidth * 1.5) * (startIndex + .5);
      const endRadius = insulatorRadiusPixels + (tieWidth * 1.5) * (endIndex + .5);
      const heightDisplacement = insulatorRadiusPixels * (side==="top"? -1 : 1);

      const controlPointOffset = insulatorRadiusPixels * 1/Math.sqrt(2) * 1.2;

      ([[2, "#000000"], [0, grad]] as [number, string|CanvasGradient][]).forEach(([widthAdjustment, style])=>{
        context.strokeStyle = style;
        context.lineWidth = tieWidth + widthAdjustment;
        context.beginPath();
        context.moveTo(canvasWidth/2 - startRadius, canvasHeight/2)
        context.quadraticCurveTo(canvasWidth/2 - controlPointOffset, canvasHeight/2 + heightDisplacement, canvasWidth/2, canvasHeight/2 + heightDisplacement);
        context.quadraticCurveTo(canvasWidth/2 + controlPointOffset, canvasHeight/2 + heightDisplacement, canvasWidth/2 + endRadius, canvasHeight/2);
        // context.quadraticCurveTo(canvasWidth/2 - startRadius, canvasHeight/2 + heightDisplacement, canvasWidth/2, canvasHeight/2 + heightDisplacement);
        // context.quadraticCurveTo(canvasWidth/2 + endRadius, canvasHeight/2 + heightDisplacement, canvasWidth/2 + endRadius, canvasHeight/2);
        //Bezier curve for cubic specification.
        context.stroke();
      });

      ([[2, "#000000"], [0, grad]] as [number, string|CanvasGradient][]).forEach(([widthAdjustment, style])=>{
        context.strokeStyle = style;
        context.lineWidth = tieWidth + widthAdjustment;
        context.beginPath();
        context.moveTo(canvasWidth/2 + startRadius, canvasHeight/2)
        context.quadraticCurveTo(canvasWidth/2 + controlPointOffset, canvasHeight/2 + heightDisplacement, canvasWidth/2, canvasHeight/2 + heightDisplacement);
        context.quadraticCurveTo(canvasWidth/2 - controlPointOffset, canvasHeight/2 + heightDisplacement, canvasWidth/2 - endRadius, canvasHeight/2);
        // context.quadraticCurveTo(canvasWidth/2 + startRadius, canvasHeight/2 + heightDisplacement, canvasWidth/2, canvasHeight/2 + heightDisplacement);
        // context.quadraticCurveTo(canvasWidth/2 - endRadius, canvasHeight/2 + heightDisplacement, canvasWidth/2 - endRadius, canvasHeight/2);
        //Bezier curve for cubic specification.
        context.stroke();
      });
    });


    context.fillStyle = "#aaaaaa";
    context.strokeStyle = "#000000";
    context.lineWidth = 1;
    context.beginPath();
    context.arc(canvasWidth / 2, canvasHeight/2, insulatorRadiusPixels, 0, Math.PI * 2, true);
    context.fill();
    context.stroke();

    context.fillStyle = "#000000"
    context.fillRect(0, canvasHeight/2 - wireWidthPixels/2, canvasWidth, wireWidthPixels);

    // --- Add Button Wire illustrations
    // Indices are where they come in under the wire. First half is under the wire, second half is on top (ending at the true end.)
    // const allButtons: [number, number, "top"|"bottom"][] = [
    //   [0, 1, "top"],
    //   [2, 3, "bottom"],
    //   [3, 4, "bottom"],
    //   [4, 5, "bottom"],
    //   [6, 7, "top"],
    //   [7, 8, "top"],
    //   // Fan Out + Tighten
    //   [8, 13, "top"],
    //   [13, 18, "top"],
    // ];
    allButtons.forEach(([startIndex, endIndex, side])=>{
      const startRadius = insulatorRadiusPixels + (tieWidth * 1.5) * (startIndex + (endIndex - startIndex)/2 + .5); // Full button wraps halfway under the line.
      const endRadius = insulatorRadiusPixels + (tieWidth * 1.5) * (endIndex + .5);
      ([[tieWidth + 2, "#000000"], [tieWidth, grad]] as [number, string|CanvasGradient][]).forEach(([widthSetting, style])=>{
        const startDisplacement = (wireWidthPixels/2 + widthSetting/2)*(side==="top"?1:-1);
        context.strokeStyle = style;
        context.lineWidth = widthSetting;
        context.beginPath();
        context.moveTo(canvasWidth/2 - startRadius, canvasHeight/2 + startDisplacement);
        context.lineTo(canvasWidth/2 - endRadius, canvasHeight/2 - startDisplacement);
        context.moveTo(canvasWidth/2 + startRadius, canvasHeight/2 + startDisplacement);
        context.lineTo(canvasWidth/2 + endRadius, canvasHeight/2 - startDisplacement);
        //Bezier curve for cubic specification.
        context.stroke();
      });
    });

    // Add (phony) weld illustration
    if (welded) {
      const weldRadius = insulatorRadiusPixels + (tieWidth * 1.5) * (currentRadius + 1 + .5) ;
      // Draw a small circle with the line width
      context.fillStyle = grad;
      context.strokeStyle = "#000000";
      context.lineWidth = 1;
      context.beginPath();
      context.arc(canvasWidth / 2 + weldRadius, canvasHeight/2, wireWidthPixels * 1.2/2, 0, Math.PI * 2, true);
      context.fill();
      context.stroke();
      context.beginPath();
      context.arc(canvasWidth / 2 - weldRadius, canvasHeight/2, wireWidthPixels * 1.2/2, 0, Math.PI * 2, true);
      context.fill();
      context.stroke();
    }


    // Add any display text.
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      // `Mouse Position X: ${mousePositionX.toFixed(2)}`,
      // `Mouse Position Y: ${mousePositionY.toFixed(2)}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  }

  const drawLoaded = (numerator:number, denominator:number) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      `Simulator Components Loaded: ${numerator}/${denominator}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  };

  useEffect(() => {
    // Making this code synchronous fixes a bug where zombie onLoad calls trigger a new draw cycle from an old state-render.
    let intervalHandle:NodeJS.Timeout|null = null;
    draw();
    intervalHandle = setInterval(draw, 1000/24);

    return (()=>{
      if (intervalHandle) {
        clearInterval(intervalHandle);
      }
    })
  }); //[]

  const promptText = "To finish replacing the pole, you need to tie-in the lines. Use the shown wire to complete a 1-3-2 top tie.";

  return (
    // TODO: Add wrapping page for demo purposes?
    // TODO: Add a note about using landscape mode...
    <IonCard style={{maxWidth: "initial"}}>
      <IonCardContent>
        {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}

        <br/>
        <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block", border:"1px solid gray"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick}/>

        <IonGrid style={{alignItems: "center", justifyContent: "center", display: "flex"}}><IonRow>
          {!ended?<>
            <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("top"); updateActions();}}>Wrap Top</IonButton></IonCol>
            <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("bottom"); updateActions();}}>Wrap Bottom</IonButton></IonCol>
            {actionsStack.length > 0? <>
              <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("button"); updateActions();}}>Button</IonButton></IonCol>
              <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("fan out"); updateActions();}}>Fan Out</IonButton></IonCol>
              <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("flatten"); updateActions();}}>Flatten Ends</IonButton></IonCol>
              <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.push("weld"); updateActions();}}>Weld Ends</IonButton></IonCol>
            </> :<></>}
          </>
          : <></>}
          {actionsStack.length > 0? <IonCol><IonButton color="secondary" onClick={()=>{actionsStack.pop(); updateActions();}}>Undo</IonButton></IonCol> : <></>}
        </IonRow></IonGrid>
        <br />

        <SynchDisabledButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("topTie", {complete: true, state: actionsStack})}}>Submit</SynchDisabledButton>
        {/* TODO: Move submit button to a wrapper element? */}

        {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
        {width < height? <IonText color="danger"><p>For best performance, consider using landscape mode.</p></IonText> : <></>}
      </IonCardContent>
    </IonCard>
  );
};

export default TopTieSimulator;