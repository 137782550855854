import { IonButton, IonSpinner } from "@ionic/react";
import { useData } from "../services/DataProvider";

const SynchDisabledButton: React.FC<{[key:string]:any}> = ({...props}) => {
  const {synching} = useData();

  //TODO: Set color of error based on ionic colors
  return <>
    <IonButton {...props} disabled={props.disabled || synching}>{synching && <IonSpinner/>} {props.children}</IonButton>
  </>
};

export default SynchDisabledButton;