import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea } from "@ionic/react"
import { alert, checkmarkCircle, helpCircle, logoFacebook, logoLinkedin } from "ionicons/icons";
import { useAuth } from "../services/AuthProvider";
import { useData } from "../services/DataProvider";
import { FacebookShareButton, FacebookShareCount, FacebookIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';
import { useEffect, useState } from "react";

const usStateCodesList = ["other", 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',];

const toPercentage = (fraction:number)=>{
  return `${((fraction || 0)*100).toFixed(0)}%`
};


const DigitalProfile: React.FC = () => {
  const {profile, setProperty, roleToSkillNames, synching} = useData();
  const {currentUser} = useAuth();
  const desiredRole = profile.role;
  const skillNames = roleToSkillNames[desiredRole] || [];
  const [updatingField, setUpdatingField] = useState("");

  const recommendedResources: {[key: string]: string} = {
    "solar": "https://irecsolarcareermap.org/",
    "wind": "https://www.energy.gov/eere/wind/wind-career-map",
    "lineman": "https://www.youtube.com/c/tdpowerskills", // "https://www.zippia.com/lineman-jobs/", // TODO: Replace with non-competing resource
  };
  const resource = desiredRole in recommendedResources ? recommendedResources[desiredRole] : "https://www.energy.gov/eere/education/map-career-clean-energy";

  let workPhrase = "on a line crew";
  if (desiredRole==="wind") {
    workPhrase = "in wind energy";
  } else if (desiredRole === "solar") {
    workPhrase = "in solar energy";
  } else if (desiredRole !== "lineman"){
    workPhrase = "in similar roles";
  }

  let socialURL = "https://powertechs.us/linemen";
  // const ICON_DEFAULTS = {
  //   round: true,
  //   size: "2em",
  // };
  if (desiredRole !== "lineman") {
    socialURL = "https://powertechs.us";
  }

  useEffect(()=>{
    // focus();
    const registerClick = () => {
      // Assumes the Facebook share button is the only iframe on the page.
      if (document.activeElement === document.querySelector('iframe')) {
        // setProperty("socialClicked", {...profile.socialClicked, "Facebook":true}); This triggers a component reload, shortcutting the actual share.
        // A stop-gap that tracks people who updated the profile after clicking the share link:
        profile["socialClicked"] = {...profile.socialClicked, "Facebook":true};
      }
    };
    window.addEventListener('blur', registerClick);

    return ()=>{
      window.removeEventListener("blur", registerClick);
    }
  }, []);

  return <IonCard>
    <IonCardHeader><IonCardTitle>{profile.contactInfo.givenName} {profile.contactInfo.surname}</IonCardTitle></IonCardHeader>
    <IonCardContent>
      {!profile.allComplete && <>
        <h1>You are almost there! One more step left - please complete your profile.</h1>
        <br/>
      </>}
      <br/>
      <h1>{desiredRole}: {profile.allComplete? <IonText color="success">profile complete</IonText> : `profile ${toPercentage(profile.fractionComplete)} complete`}</h1>
      {profile.allComplete? <>
        <h2>Score: {profile?.matchFractions?.["match"]? toPercentage(profile?.matchFractions?.["match"]) : "Hidden"}</h2>
        {/* Temporarily disabling leaderboard. */}
        {/* <h2><IonRouterLink href="/leaderboard">See Leaderboard</IonRouterLink></h2> */}
      </>: <></>}

      {synching && <>
        <p><IonSpinner/> Your data is being processed...</p>
        <br/>
      </>}

      <IonGrid>
        <IonRow>
          <IonCol>
            <h2>Contact</h2>
            <p>Email: {currentUser?.email}</p>
            <p>Phone: {profile.contactInfo.phone}</p>
            <p>LinkedIn (Optional):</p>
            <IonItem>
              <IonLabel>linkedin.com/in/</IonLabel>
              <IonInput disabled={(updatingField && updatingField!=="linkedIn")||synching} value={profile?.contactInfo?.linkedin} onBlur={(event)=>{(profile?.contactInfo?.linkedin !== (event.target.value || "")) && setProperty("contactInfo", {...profile.contactInfo, linkedin: event.target.value}); setUpdatingField("")}} onFocus={()=>{setUpdatingField("linkedIn")}}></IonInput>
            </IonItem>
            <br/>
            <p>{!profile?.completeDictionary?.["state"]? <IonText color="warning"><IonIcon icon={helpCircle}/></IonText> : <IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>} State of Residence: </p>
            {!synching && <>
              <IonSelect disabled={synching} value={profile?.contactInfo?.state} placeholder="Select State" onIonChange={(event)=>{setProperty("contactInfo", {...profile.contactInfo, state: event.target.value})}}>
                {usStateCodesList.map((code, index)=>{
                  return <IonSelectOption key={index}>{code}</IonSelectOption>
                })}
              </IonSelect>
              {/* There is a peculiar bug when this is rendered while the state is updating, causing the state to revert. By not rendering it, the onIonChange isn't triggered (this is my guess, anyway), so there's no issue. */}
            </>}

            <h2>Skills:</h2>
            {skillNames.map((skillName, index)=>{
              return <h2 key={index}>{!profile?.completeDictionary?.[skillName]? <IonText color="warning"><IonIcon icon={helpCircle}/></IonText> : <IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>} {profile?.completeDictionary?.[skillName]? skillName :
                <IonButton onClick={()=>{setProperty("activeQuestions", skillName)}}>{skillName}</IonButton>
              }</h2>
            })}
          </IonCol>
          <IonCol>
            <h2>{!profile?.completeDictionary?.["workHistory"]? <IonText color="warning"><IonIcon icon={helpCircle}/></IonText> : <IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>} Work History</h2>
            <p>Do you have any experience working {workPhrase}? If so, please indicate the number of years (approximately) that you have worked. Put 0 if you have not worked {workPhrase}.</p>
            <IonItem>
              <IonLabel></IonLabel>
              <IonInput disabled={(updatingField && updatingField!=="workHistory")||synching} value={profile?.["workHistoryYears"]} type="number" onBlur={(event)=>{(profile?.["workHistoryYears"] !== (event.target.value || "")) && setProperty("workHistoryYears", event.target.value); setUpdatingField("");}} onFocus={()=>{setUpdatingField("workHistory")}}></IonInput>
            </IonItem>
            <br/>

            <h2>{!profile?.completeDictionary?.["communication"]? <IonText color="warning"><IonIcon icon={helpCircle}/></IonText> : <IonText color="success"><IonIcon icon={checkmarkCircle}/></IonText>} Communication</h2>
            {desiredRole==="lineman"? 
              <p>What's something only a lineman would know? How would you explain it to someone else?</p>
            : 
              <p>What's something that only an expert in this role would know? How would you explain it to someone else?</p>
            }
            <IonItem>
              <IonTextarea disabled={(updatingField && updatingField!=="communication")||synching} autoGrow value={profile?.["communication"]} onBlur={(event)=>{(profile?.["communication"] !== (event.target.value || "")) && setProperty("communication", event.target.value); setUpdatingField("")}} onFocus={()=>{setUpdatingField("communication")}}/>
            </IonItem>
            <br/>

            <h2>Recommended Resources</h2>
            <p>{resource}</p>
          </IonCol>
        </IonRow>
        <br/>
        <br/>
        <br/> {/* Extra spacing. */}
        <IonRow>
          <IonCol>
            <p>Share on social media to be entered in a drawing for swag and other prizes!</p>
            {/* https://developers.facebook.com/docs/plugins/share-button/ */}
            {/* <div className="fb-share-button" data-href="https://powertechs.us/linemen" data-layout="button_count" data-size="large"><a target="_blank" rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpowertechs.us%2Flinemen&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div> */}
            {/* https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/plugins/share-plugin */}
            {/* <script type="IN/Share" data-url="https://powertechs.us/linemen"></script> */}
            {/* <div style={{display: "flex", justifyContent:"space-between", alignItems:"center", maxWidth:"20em"}}> */}
            <div style={{display:"flex", justifyContent:"start"}}>
              <h3>Share:</h3>
              {/* https://www.npmjs.com/package/react-share */}

              {/* <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fpowertechs.us%2Flinemen&layout=button_count&size=large&width=88&height=28&appId" width="88" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
              {/* <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fpowertechs.us%2Flinemen&layout=button_count&size=large&width=88&height=28&appId" width="88" height="28" style={{border:"none", overflow:"hidden", marginLeft:".5em"}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}

              {/* quote property doesn't seem to work, so we have to rely on page metadata. */}
              <FacebookShareButton url={socialURL}  beforeOnClick={()=>{setProperty("socialClicked", {...profile.socialClicked, "Facebook":true});}}>
                {/* <IonIcon icon={logoFacebook} style={{fontSize: "2em", marginLeft:".5em"}} /> */}
                {/* <FacebookShareCount url={socialURL} /> */}
                <img src="./assets/social/facebook-share-button-icon.svg" style={{height: "2em", marginLeft:".5em"}}/>
              </FacebookShareButton>
              {/* title and summary properties don't seem to work, so we have to rely on page metadata. */}
              <LinkedinShareButton url={socialURL} beforeOnClick={()=>{setProperty("socialClicked", {...profile.socialClicked, "LinkedIn":true});}} summary="Testing Summary">
                {/* <LinkedinIcon {...ICON_DEFAULTS} /> */}
                {/* <IonIcon icon={logoLinkedin} style={{fontSize: "2em", color:"#0077B5", marginLeft:".5em"}} /> */}
                <img src="./assets/social/linkedin-share-button-icon.svg" style={{height: "2em", marginLeft:".5em"}}/>
              </LinkedinShareButton>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonCard>
}

export default DigitalProfile;