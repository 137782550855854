import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonInput, IonItem, IonLabel, IonRouterLink, IonText } from "@ionic/react";
import { FormEvent, useRef, useState } from "react";
// import { useHistory } from "react-router";
// import { useAuth } from "reactfire";
import { useAuth } from "../services/AuthProvider";
import useSubdomain from "../services/SubdomainMapper";

const SignUp: React.FC = ({...props}) => {
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const confirmPasswordRef = useRef<HTMLIonInputElement>(null);
  const readAgreementsRef = useRef<HTMLIonCheckboxElement>(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {signup} = useAuth(); 
  // const auth = useAuth();

  // const history = useHistory();

  const subdomain = useSubdomain();

  let tagline = "Find a new job in clean energy."
  if (subdomain === "hr") {
    tagline = "Fill the skills gap for your company.";
  } else if (subdomain === "linemen") {
    tagline = "Compete for cash prizes and see where your skills rank!";
  }

  async function handleSubmit(event:FormEvent) {
    event.preventDefault(); // Prevents page from refreshing on form submit.

    if(!readAgreementsRef.current?.checked) {
      setError("You must agree to the Privacy Policy and Terms and Conditions.");
      return;
    }

    // if (("" + passwordRef.current?.value).length <= 0) { // This is effectively checked by Firebase's auth requirements.
    //   setError("Password cannot be empty.");
    //   return;
    // }

    if (passwordRef.current?.value !== confirmPasswordRef.current?.value) {
      setError("Passwords do not match.");
      return;
    }

    setError('');
    setLoading(true); //Because the function is async, maybe it is possible for rerender to happen while loading.
    await (signup("" + emailRef.current?.value, "" + passwordRef.current?.value)?.then(()=>{
      setLoading(false);
      // history.push("/"); //Redirect to home page/dashboard after login.
      // REDIRECT SHOULD HAPPEN IN THE ROUTER.
    }).catch((reason)=>{
      setError('Failed to create an account: ' + (reason.message || ""));
      //console.log(reason); //TODO: Present to the user.
      setLoading(false);
    }));
  }

  //TODO: Set color of error based on ionic colors
  return <>
  <IonCard>
  {/* <img src="/assets/logos/PowerTechsLogoColor.svg" style={{margin:"1em", height:"4em", float:"right"}}/> */}
    <IonCardHeader>
      <IonCardTitle>Join <img src="/assets/logos/PowerTechsIcon.svg" style={{height:"1.2em", position:"relative", top:".23em"}}/>PowerTechs</IonCardTitle>
      <IonText color="dark"><h5>{tagline}</h5></IonText>
    </IonCardHeader>
    <IonCardContent>
      {!!error? <IonText color={'danger'}><h3>{error}</h3></IonText>: <></>}
      <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput type="email" ref={emailRef} placeholder="example: first.last@gmail.com"/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Password</IonLabel>
        <IonInput type="password" ref={passwordRef}/>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Confirm Password</IonLabel>
        <IonInput type="password" ref={confirmPasswordRef}/>
      </IonItem>
      <IonItem>
        <IonCheckbox slot="start" ref={readAgreementsRef}/>
        <IonLabel text-wrap><IonText color="medium">I have read and agree to the <IonRouterLink href="https://powertechs.us/privacy" color="medium">Privacy Policy</IonRouterLink> and <IonRouterLink href="https://powertechs.com/terms" color="medium">Terms and Conditions</IonRouterLink>.</IonText></IonLabel>
      </IonItem>
      <IonButton expand="block" type="submit" className="ion-margin-top" disabled={loading}>
        Register
      </IonButton>
      </form>
      <IonText color="medium"><p>Already have an account? <IonRouterLink routerLink="/login">Log In</IonRouterLink></p></IonText>
    </IonCardContent>
  </IonCard>
  </>
};

export default SignUp;

