import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { isAdmin } from "common";
import { Redirect, Route, Switch } from "react-router";
import AdminCreateQuestion from "./components/AdminCreateQuestion";
import AdminListUsers from "./components/AdminListUsers";
import DistributionDemo from "./components/DistributionDemo";
import ForgotPassword from "./components/ForgotPassword";
import SolarDemo from "./components/SolarDemo";
import SynchDisabledButton from "./components/SynchDisabledButton";
import UpdateProfile from "./components/UpdateProfile";
import WindDemo from "./components/WindDemo";
import Page404 from "./pages/404";
import CircuitTestingSimulator from "./pages/CircuitTestingSimulator";
import DemoHome from "./pages/DemoHome";
import Home from "./pages/Home";
import HRHome from "./pages/HRHome";
import InspectionSimulator from "./pages/InspectionSimulator";
import JobsLandingPage from "./pages/JobsLandingPage";
import Leaderboard from "./pages/Leaderboard";
import { useAuth } from "./services/AuthProvider";
import { useData } from "./services/DataProvider";
import useSubdomain from "./services/SubdomainMapper";

const AppRouter: React.FC = () => {
  const {currentUser} = useAuth();

  // let domainName = window.location.hostname.toLowerCase();
  // domainName = (domainName === "localhost"? "jobs.powertechs.us" : domainName);
  const appSubdomain = useSubdomain();

  const {profile, setProperty, queryParameters} = useData();
  // queryParameters is a persistent mutable that can be directly saved to (write properties directly).
  // designed to quickly cache utm parameters before page is loaded.
  // Only needs to store external query parameters, so it can run the first time this renders.
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  Object.assign(queryParameters, params);

  return <IonReactRouter>
    <Switch>
      {/* <Route exact path="/windtechs">
        {profile["SurveyDistance"] ? 
          (profile["TurbineControl"]? 
            (profile["BoltTightening"]? 
              (profile["SolarWiring"]? <FeedbackPage /> : <SolarWiringSimulator/>)
            : <BoltTighteningSimulator/>)
          : <ControlPanelSimulator />) 
        : <InspectionSimulator /> 
        }
        
      </Route> */}

      <Route exact path="/admin">
        {isAdmin(currentUser?.email || "")? 
          <IonPage><IonContent>
            <AdminCreateQuestion/>
            <AdminListUsers />
          </IonContent></IonPage>
        : <Redirect to="/home"/>}
      </Route>

      <Route exact path={["/roi", "/skills", "/readiness"]}>
        {/* Placed for convenience of access; TODO: Not the permanent home. */}
        <DemoHome />
      </Route>

      {appSubdomain === "demo"?
        <Route exact path="/I&C">
          <IonPage><IonContent><CircuitTestingSimulator /></IonContent></IonPage>
        </Route>:
        null // NOTE: <></> shortcuts the Switch for some reason.
      }

      {["jobs", "linemen", "solartechs", "windtechs"].includes(appSubdomain)?
        <Route exact path={["/home", "/portfolio", "/complete"]}>
          {currentUser? <Home /> : <Redirect to="/login" />}
        </Route>
      : appSubdomain==="hr"?
        <Route exact path="/home">
          <HRHome />
        </Route>
      : appSubdomain==="solar-demo"?
        <Route>
          <IonPage><IonContent><SolarDemo /></IonContent></IonPage>
        </Route>
      : appSubdomain==="wind-demo"?
        <Route>
          <IonPage><IonContent><WindDemo /></IonContent></IonPage>
        </Route>
      : appSubdomain==="demo"?
        <Route>
          <IonPage><IonContent><DistributionDemo /></IonContent></IonPage>
        </Route>
      :  <Route> {/* Matches all for demo subdomain. */}
          {/* <DemoHome /> */}

          <IonPage><IonContent>
            {!profile.role && <IonCard>
              <IonCardHeader><IonCardTitle>What kind of roles are you interested in?</IonCardTitle></IonCardHeader>
              <IonCardContent>
                <p>Welcome to the PowerTechs.us live demo! To get started, please indicate which role's skills you would like to assess.</p>
                <SynchDisabledButton onClick={()=>{setProperty("role", "wind")}}>Wind Technician</SynchDisabledButton>
                <SynchDisabledButton onClick={()=>{setProperty("role", "solar")}}>Solar Technician</SynchDisabledButton>
                {/* <IonButton onClick={()=>{setProperty("role", "automation")}}>Instrumentation & Control Technician</IonButton> */}
                <SynchDisabledButton onClick={()=>{setProperty("role", "lineman")}}>Distribution Lineworker</SynchDisabledButton>
              </IonCardContent>
            </IonCard>}
            {(profile.role==="lineman") && <DistributionDemo />}
            {(profile.role==="solar") && <SolarDemo />}
            {(profile.role==="wind") && <WindDemo />}
          </IonContent></IonPage>
        </Route>
      }

      <Route exact path="/leaderboard">
        <Leaderboard />
      </Route>

      <Route exact path={["/login", "/signup"]}>
        {currentUser? <Redirect to="/" /> : <JobsLandingPage/>}
      </Route>
      <Route exact path="/forgot-password">
        {currentUser? <Redirect to="/" /> : <IonPage><IonContent><ForgotPassword /></IonContent></IonPage>}
      </Route>
      <Route exact path="/update-profile">
        {currentUser? <IonPage><IonContent><UpdateProfile /></IonContent></IonPage> : <Redirect to="/" />}
      </Route>

      <Route exact path="/"> {/* Only for non-demo */}
        {currentUser? <Redirect to="/home" /> 
        : <Redirect to="/login" />}
      </Route>

      <Route>{/* Default catch-all route at end. */}
        <Page404 />
      </Route>
    </Switch>
  </IonReactRouter>
};

export default AppRouter;