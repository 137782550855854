import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import SynchDisabledButton from "../components/SynchDisabledButton";
import { useData } from "../services/DataProvider";
import useWindowDimensions from "../services/WindowDimensions";

export type position = [number, number, number, number]; // x, y, width, height.

const EquipmentLoads: React.FC = () => {
  const {profile, setProperty} = useData();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  let intervalHandleInit = null as NodeJS.Timeout|null;
  let intervalHandle = useRef(intervalHandleInit);

  const {width, height} = useWindowDimensions(); //Note: Uses a custom-width card to achieve this.

  // Detect Mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const aspectRatio = 16/9;
  const windowFraction = isMobile? .95 : .85;
  const canvasWidth = Math.min(width * windowFraction, height * windowFraction * aspectRatio);
  const canvasHeight = canvasWidth / aspectRatio;

  const poleWidthUnits = .33; // A third of a meter.
  const poleHeightUnits = 12;

  const heightUnits = poleHeightUnits + .5 * 2; // 14 meter(s) -> 35 feet for the pole, 5 above, and 5 below.
  const widthUnits = aspectRatio * heightUnits;
  const unitsToPixels = canvasWidth / widthUnits;


  const poleWidthPixels = Math.max(poleWidthUnits * unitsToPixels, 5);
  const poleHeightPixels = poleHeightUnits * unitsToPixels;
  const leftoverHeight = canvasHeight - poleHeightPixels;

  const groundPosition = 1 - (heightUnits - poleHeightUnits)/(2*heightUnits);

  const HDPIUpscale = 1; // Got this from https://dev.to/ycmjason/do-you-know-about-these-svg-techniques-2k3o, not sure if it's actually necessary. Should change to 2 or higher if relevant.

  const clickableRadiusUnits = heightUnits/20;

  const assets = {
    "background": {src: "./assets/tasks/EquipmentLoads/SourceImages/background.png"},
    "lineman": {src:"./assets/tasks/HangingTransformer/linemanCropped.svg"},
    "person": {src:"./assets/tasks/Person.svg"},
    "transformer": {src:"./assets/tasks/HangingTransformer/transformerCropped.svg"},
    "1000PoundRope": {src: "./assets/tasks/EquipmentLoads/SourceImages/1000PoundRope.jpg"},
    "3000PoundRope": {src: "./assets/tasks/EquipmentLoads/SourceImages/3000PoundRope.png"},
    "6000PoundRope": {src: "./assets/tasks/EquipmentLoads/SourceImages/6000PoundRope.png"},
    "1900PoundGin": {src: "./assets/tasks/EquipmentLoads/SourceImages/1900PoundGin.jpg"},
    "3250PoundGin": {src: "./assets/tasks/EquipmentLoads/SourceImages/3250PoundGin.webp"},
    "4000Pound4x4Block": {src: "./assets/tasks/EquipmentLoads/SourceImages/4000Pound4x4Block.jpg"},
    "2000Pound3x3Block": {src: "./assets/tasks/EquipmentLoads/SourceImages/2000Pound3x3Block.jpg"},
  };

  const imagesInit = Object.fromEntries(Object.entries(assets).map(([key, value])=>{
    return [key, new Image()]
  })) as Record<keyof typeof assets, HTMLImageElement>;
  const images = imagesInit;

  const linemanHeightUnits = 2;
  const linemanFractionFromTop = .4; // Actually not sure how they get up the pole to connect the transformer wires after installing...
  const instancesInit: {name:keyof typeof assets, position: position, caption?:string, cover?:boolean, group?:string}[] = [
    {name: "background", position: [0, 0, 1, 1], cover: true},
    {name: "lineman", position: [.5, linemanFractionFromTop, 1/(widthUnits), linemanHeightUnits / heightUnits]},
    {name: "transformer", position: [.45, groundPosition - 1/heightUnits, .5/widthUnits, 1/heightUnits]},
    {name: "person", position: [.15, groundPosition - linemanHeightUnits/heightUnits, .5/widthUnits, linemanHeightUnits/heightUnits]},
    {name: "person", position: [.2, groundPosition - linemanHeightUnits/heightUnits, .5/widthUnits, linemanHeightUnits/heightUnits]},
    {name: "person", position: [.35, groundPosition - linemanHeightUnits/heightUnits, .5/widthUnits, linemanHeightUnits/heightUnits]},

    {name: "1000PoundRope", position: [.1, 0, .2, 1], caption: "Rope: 1000 lb rating", group: "tagline"},
    {name: "3000PoundRope", position: [.4, 0, .2, 1], caption: "Rope: 3000 lb rating", group: "tagline"},
    {name: "6000PoundRope", position: [.7, 0, .2, 1], caption: "Rope: 6000 lb rating", group: "tagline"},

    {name: "1900PoundGin", position: [.1, 0, .35, 1], caption: "Gin: 1900 lb rating", group: "gin"},
    {name: "3250PoundGin", position: [.55, 0, .35, 1], caption: "Gin: 3250 lb rating", group: "gin"},

    {name: "2000Pound3x3Block", position: [.1, 0, .35, 1], caption: "3-3 Pulley: 2000 lb rating", group: "pulley"},
    {name: "4000Pound4x4Block", position: [.55, 0, .35, 1], caption: "4-4 Pulley: 4000 lb rating", group: "pulley"},

    {name: "1000PoundRope", position: [.1, 0, .2, 1], caption: "Rope: 1000 lb rating", group: "lifting"},
    {name: "3000PoundRope", position: [.4, 0, .2, 1], caption: "Rope: 3000 lb rating", group: "lifting"},
    {name: "6000PoundRope", position: [.7, 0, .2, 1], caption: "Rope: 6000 lb rating", group: "lifting"},
  ];

  const transformer = instancesInit[2];
  const pulleyPersonFar = instancesInit[3];
  const pulleyPersonNear = instancesInit[4];
  const taglinePerson = instancesInit[5];

  const getCenterXY = (inputPosition: position)=>{
    const centerX = inputPosition[0] + .5 * inputPosition[2];
    const centerY = inputPosition[1] + .5 * inputPosition[3];
    return [centerX, centerY];
  }

  const [transformerCenterX, transformerCenterY] = getCenterXY(transformer.position);
  const [personNearCenterX, personNearCenterY] = getCenterXY(pulleyPersonNear.position);
  const [personFarCenterX, personFarCenterY] = getCenterXY(pulleyPersonFar.position);
  const [taglinePersonCenterX, taglinePersonCenterY] = getCenterXY(taglinePerson.position);
  const [pulleyX, pulleyY] = [.5, linemanFractionFromTop - .5/heightUnits];

  let mousePositionXInit = 0;
  let mousePositionYInit = 0;

  // https://bobbyhadz.com/blog/typescript-index-signature-parameter-cannot-be-union-type#:~:text=The%20error%20%22An%20index%20signature,in%20MyUnion%5D%3A%20string%3B%7D%20.
  type lineType = "hand"|"tag"|"pulley"|"pulleyHand"|"pulleyPeople";
  const linePositionsInit: {[key in lineType]: {color:string, position: position|null, weight?: number}} = {
    "hand": {color: "#f5ce42", position: null},
    "tag": {color: "#f2711b", position: [transformerCenterX, transformerCenterY, taglinePersonCenterX - transformerCenterX, taglinePersonCenterY - transformerCenterY]},
    "pulley": {color: "#02c443", position: [transformerCenterX, transformerCenterY, pulleyX - transformerCenterX, pulleyY-transformerCenterY], weight: 2}, // 5 ropes, but the ropes are drawn thick already.
    "pulleyHand": {color: "#02c443", position: [pulleyX, pulleyY, personNearCenterX - pulleyX, personNearCenterY - pulleyY]},
    "pulleyPeople": {color: "#02c443", position: [personNearCenterX, personNearCenterY, personFarCenterX - personNearCenterX, personFarCenterY - personNearCenterY]},
  };

  const selectableCenters: {[key: string]: [number, number]} = {
    "lifting": [personNearCenterX, personNearCenterY],
    "tagline": [taglinePersonCenterX, taglinePersonCenterY],
    "gin": [pulleyX, pulleyY],
    "pulley": [transformerCenterX, transformerCenterY],
  };

  const selectedLoadsInit: {[key:string]: string} = {};

  // https://reactjs.org/docs/hooks-reference.html#useref
  // Ref for mutable state to persist across window resize.
  const simulatorState = useRef({ // Simulator State.
    instances: instancesInit,
    mousePositionX: mousePositionXInit,
    mousePositionY: mousePositionYInit,
    linePositions: linePositionsInit, // Not really needed for grading, but keeping it around anyway.
    selectedLoads: selectedLoadsInit,
    activeSelection: null as null | string,
  }); // TODO: Move images to a useMemo or something? Shouldn't wind up in the database-storable state. Currently images are reloaded every time the simulator reloads. Consider making a separate useRef or something and changing useEffect to not reload if they are already loaded.
  const state = simulatorState.current; // Shortform reference to the mutable object.

  const updateMousePosition = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    state.mousePositionX = (event.clientX - canvas.getBoundingClientRect().left)/(canvasWidth); // Fraction 0-1
    state.mousePositionY = (event.clientY - canvas.getBoundingClientRect().top)/(canvasHeight); // Fraction 0-1

    // state.instances.forEach((instance, index)=>{
    //   if (checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
    //     instance.mouseover = true; // This updates the original object.
    //   } else {
    //     instance.mouseover = false;
    //   }
    // });
  }

  const checkInside = (mouseX:number, mouseY:number, boundingBox:position) => {
    return mouseX >= boundingBox[0] && mouseX <= boundingBox[2] + boundingBox[0] && mouseY >= boundingBox[1] && mouseY <= boundingBox[3] + boundingBox[1];
  }

  const fractionToRealPositions = ([fractionX, fractionY, fractionWidth, fractionHeight]:position):position => {
    return [fractionX * canvasWidth, fractionY * canvasHeight, fractionWidth * canvasWidth, fractionHeight * canvasHeight];
  };

  // Both of these assume square coordinate systems (pixel space, not fraction space).
  const containFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the bigger of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const biggerRatio = Math.max(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / biggerRatio;
    const chosenHeight = imageHeight / biggerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const coverFit = (imageWidth:number, imageHeight:number, targetPosition:position):position=>{
    // Identify the smaller of the two ratios.
    const [targetX, targetY, targetWidth, targetHeight] = targetPosition;
    const smallerRatio = Math.min(imageWidth/targetWidth, imageHeight/targetHeight);
    const chosenWidth = imageWidth / smallerRatio;
    const chosenHeight = imageHeight / smallerRatio;
    const chosenX = targetX + (targetWidth - chosenWidth)/2;
    const chosenY = targetY + (targetHeight - chosenHeight)/2;
    return [chosenX, chosenY, chosenWidth, chosenHeight];
  };

  const onClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    // Update Selected Region
    if (!state.activeSelection) {
      Object.keys(selectableCenters).forEach((key)=>{
        const [centerX, centerY] = selectableCenters[key];
        const xInside = (state.mousePositionX <= centerX + clickableRadiusUnits/widthUnits) && (state.mousePositionX >= centerX - clickableRadiusUnits/widthUnits);
        const yInside = (state.mousePositionY <= centerY + clickableRadiusUnits/heightUnits) && (state.mousePositionY >= centerY - clickableRadiusUnits/heightUnits);
        if (xInside && yInside) {
          state.activeSelection = key;
        }
      });
    } else {
      // Check if any of the relevant items were selected.
      state.instances.forEach((instance)=>{
        if (instance.group && (instance.group===state.activeSelection)) {
          if (checkInside(state.mousePositionX, state.mousePositionY, instance.position)) {
            state.selectedLoads[state.activeSelection] = instance.name;
            state.activeSelection = null;
          }
        }
      })
    }
  }

  const draw = () => { // context:CanvasRenderingContext2D
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw the background image
    const background = state.instances.filter((instance)=>{return instance.name==="background"});
    background.forEach((backgroundInstance)=>{
      const itemImage = images[backgroundInstance.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(backgroundInstance.position);
      const fitPosition = coverFit(itemImage.width, itemImage.height, targetPosition);

      context.drawImage(itemImage, ...fitPosition);
    })

    // Draw the Pole, crossbar, and lines.
    context.fillStyle = '#000000'
    context.fillRect(canvasWidth/2 - poleWidthPixels/2, leftoverHeight/2, poleWidthPixels, poleHeightPixels);

    const crossbarWidthUnits = .17; // A sixth of a meter
    const crossbarWidthPixels = Math.max(crossbarWidthUnits * unitsToPixels, 5);
    const crossbarLengthUnits = 1; // 1.3; // About 4 feet (actually a good bit longer because of perspective.)
    const crossbarLengthPixels = crossbarLengthUnits * unitsToPixels;
    const midpointHeight = leftoverHeight/2 + (crossbarLengthUnits)*unitsToPixels;
    const angleDisplacement = .25*unitsToPixels; // was .5 * unitsToPixels
    context.strokeStyle = "#000000"
    context.lineWidth = crossbarWidthPixels;
    context.beginPath();
    context.moveTo(canvasWidth/2 - crossbarLengthPixels/2, midpointHeight - angleDisplacement);
    context.lineTo(canvasWidth/2 + crossbarLengthPixels/2, midpointHeight + angleDisplacement);
    context.stroke();
    
    const lineFractions = [.1, .35, .9];
    context.lineWidth = Math.max(2, crossbarWidthPixels/4);
    lineFractions.forEach((fraction)=>{
      const mountHeight = midpointHeight - angleDisplacement + fraction*2*angleDisplacement;
      const mountX = canvasWidth/2 - crossbarLengthPixels/2 + fraction*crossbarLengthPixels;
      const sagUnits = 1;
      const sagPixels = sagUnits * unitsToPixels;
      context.strokeStyle = "#000000";
      context.beginPath();
      context.moveTo(mountX - canvasWidth/2 * 1.2, mountHeight + sagPixels);
      context.quadraticCurveTo(mountX - canvasWidth/4, mountHeight + sagPixels, mountX, mountHeight);
      context.quadraticCurveTo(mountX + canvasWidth/4, mountHeight + sagPixels, mountX+ canvasWidth/2 * 1.2, mountHeight + sagPixels);
      context.stroke();
    });

    // Draw image instances in order.
    const nonBackgrounds = state.instances.filter((instance)=>{return instance.name!=="background"});
    nonBackgrounds.forEach((item)=>{
      // Shortcut if it is not the active group
      if (item.group) {
        return;
      }

      const itemImage = images[item.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(item.position);
      const fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);

      // if (item.active) {
      //   const [fitX, fitY, fitWidth, fitHeight] = fitPosition;
      //   const border = 5; // px.
      //   context.fillStyle = "#999999";
      //   context.fillRect(fitX - border, fitY - border, fitWidth + 2*border, fitHeight + 2*border);
      // }

      // if (item.mouseover) {
      //   context.globalAlpha = .8;
      // }
      context.drawImage(itemImage, ...fitPosition);
      if (item.caption) {
        const captionHeight = canvasHeight/25; // Arbitrary. Still looks okay on iPhone.
        context.font = `${captionHeight}px sans serif`;
        const measure = context.measureText(item.caption);
        // Place Caption Background
        context.globalAlpha = .5;
        context.fillStyle = "#FFFFFF";
        context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3] - captionHeight, measure.width, captionHeight * 1.5); // Scale by 1.5 to cover below letters too.
        //context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3], 100, 100);
        context.globalAlpha = 1;
        // Place Caption
        context.strokeStyle = "#FFFFFF";
        context.strokeText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
        context.fillStyle = "#000000";
        context.fillText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
      }
      context.globalAlpha = 1;

      // Debugging only to adjust windows on dev screen:
      const debugging = false;
      if (window.location.hostname.toLowerCase()==="localhost" && debugging) { // can be turned on and off.
        context.strokeStyle = "#00FF00";
        context.strokeRect(...targetPosition);
        context.strokeStyle = "#0000FF";
        context.strokeRect(...fitPosition);
      }
    })

    // Draw the lines, if they have positions
    Object.keys(state.linePositions).forEach((key)=>{
      const {color, position, weight} = state.linePositions[key as lineType];
      if (position) {
        const coords = fractionToRealPositions(position);
        const ropeSagUnits = 1;
        const ropeSagPixels = ropeSagUnits * unitsToPixels;
        context.strokeStyle = color;
        context.lineWidth = Math.max(canvasHeight/100, 5) * (weight || 1);
        context.beginPath();
        context.moveTo(coords[0], coords[1]);
        context.quadraticCurveTo(coords[0] + .5* coords[2], .5 * coords[3] + coords[1] + ropeSagPixels, coords[2] + coords[0], coords[3]+coords[1])
        //context.lineTo(coords[2] + coords[0], coords[3]+coords[1]);
        context.stroke();
      }
    });

    // Draw the grouped items
    nonBackgrounds.forEach((item)=>{
      // Shortcut if it is not the active group
      if (!item.group || (item.group !== state.activeSelection)) {
        return;
      }

      const itemImage = images[item.name];
      const imageHeight = itemImage.height;
      const imageWidth = itemImage.width;

      const targetPosition = fractionToRealPositions(item.position);
      const fitPosition = containFit(itemImage.width, itemImage.height, targetPosition);

      // if (item.active) {
      //   const [fitX, fitY, fitWidth, fitHeight] = fitPosition;
      //   const border = 5; // px.
      //   context.fillStyle = "#999999";
      //   context.fillRect(fitX - border, fitY - border, fitWidth + 2*border, fitHeight + 2*border);
      // }

      // if (item.mouseover) {
      //   context.globalAlpha = .8;
      // }
      context.drawImage(itemImage, ...fitPosition);
      if (item.caption) {
        const captionHeight = canvasHeight/25; // Arbitrary. Still looks okay on iPhone.
        context.font = `${captionHeight}px sans serif`;
        const measure = context.measureText(item.caption);
        // Place Caption Background
        context.globalAlpha = .5;
        context.fillStyle = "#FFFFFF";
        context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3] - captionHeight, measure.width, captionHeight * 1.5); // Scale by 1.5 to cover below letters too.
        //context.fillRect(fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3], 100, 100);
        context.globalAlpha = 1;
        // Place Caption
        context.strokeStyle = "#FFFFFF";
        context.strokeText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
        context.fillStyle = "#000000";
        context.fillText(item.caption, fitPosition[0] + (fitPosition[2]-measure.width)/2, fitPosition[1] + fitPosition[3]);
      }
      context.globalAlpha = 1;

      // Debugging only to adjust windows on dev screen:
      const debugging = false;
      if (window.location.hostname.toLowerCase()==="localhost" && debugging) { // can be turned on and off.
        context.strokeStyle = "#00FF00";
        context.strokeRect(...targetPosition);
        context.strokeStyle = "#0000FF";
        context.strokeRect(...fitPosition);
      }
    })

    // // Draw a highlight circle based on the active line.
    // if (state.activeLine) {
    //   context.fillStyle = state.linePositions[state.activeLine].color + "80" // Add 50% alpha.
    //   context.beginPath()
    //   context.arc(state.mousePositionX * canvasWidth, state.mousePositionY * canvasHeight, canvasHeight/20, 0, Math.PI * 2, true);
    //   context.fill();
    // }

    // Draw a highlight where each item is selectable, red if undetermined, green if set.
    if (!state.activeSelection) {
      Object.keys(selectableCenters).forEach((key)=>{
        if (state.selectedLoads[key]) {
          context.fillStyle = "#00FF0080"; // 50% alpha
        } else {
          context.fillStyle = "#FF000080";
        }
      context.beginPath()
      context.arc(selectableCenters[key][0] * canvasWidth, selectableCenters[key][1] * canvasHeight, clickableRadiusUnits * unitsToPixels, 0, Math.PI * 2, true);
      context.closePath();
      context.fill();
      })
    }

    // const weightReading = state.instances.reduce((cumulative, current)=>{
    //   if (current.active) {
    //     cumulative += current.weight;
    //   }
    //   return cumulative;
    // }, 0);
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      // `${state.handAboveWorker}, ${state.handCorrectSide}, ${state.tagOnTransformer}, ${state.tagCorrectSide}, ${state.pulleyAboveWorker}, ${state.pulleyOnTransformer}`,
      // `Selected Equipment Weight: ${weightReading.toFixed(1)} kg`,
      // `Mouse Position X: ${mousePositionX.toFixed(2)}`,
      // `Mouse Position Y: ${mousePositionY.toFixed(2)}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  }

  const drawLoaded = (numerator:number, denominator:number) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    // Clear out the background
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    // Debugging only:
    context.fillStyle = "#000000";
    context.font = `${canvasHeight/20}px sans serif`;
    const texts: string[] = [
      `Simulator Components Loaded: ${numerator}/${denominator}`,
    ];
    texts.forEach((value, index)=>{
      context.fillText(value, 0, (index + 1) * canvasHeight/20);
    });
  };

  useEffect(() => {
    var loaded = 0; // Counts up to the number of items; note: images are reloaded (I think) every render.
    const onload = () => {
      loaded = loaded + 1;
      if (loaded < Object.keys(images).length) {
        drawLoaded(loaded, Object.keys(images).length); // Draw Loading Update.
        return;
      }

      draw();

      if (!intervalHandle.current) {
        intervalHandle.current = setInterval(draw, 1000/24);
      }
    }

    drawLoaded(loaded, Object.keys(images).length);

    let key: keyof typeof assets;
    for (key in images) {
      images[key].onload = onload
      images[key].src = assets[key].src;
    } // This initiates (I think) the reload of images, even if the src is the same.

    return (()=>{
      if (intervalHandle.current) {
        clearInterval(intervalHandle.current);
        intervalHandle.current = null;
      }
    })
  }); //[]

  const promptText = "Your team needs to hang an 850 lb. transformer. For each piece of highlighted equipment, select the smallest option that still has the needed load rating.";

  return (
    // TODO: Add wrapping page for demo purposes?
    // TODO: Add a note about using landscape mode...
    <IonCard style={{maxWidth: "initial"}}>
      <IonCardContent>
        {isMobile? <p>{promptText}</p> : <h1 style={{textAlign:"center", fontSize:"2em"}}>{promptText}</h1>}

        <br/>
        <canvas ref={canvasRef} width={canvasWidth*HDPIUpscale} height={canvasHeight*HDPIUpscale} style={{width:`${canvasWidth}px`, height:`${canvasHeight}px`, margin:"auto", display:"block"}} onMouseMove={(event)=>{updateMousePosition(event);}} onClick={onClick}/>

        <br />

        <SynchDisabledButton style={{textAlign:"center", margin:"auto", display:"block"}} onClick={()=>{setProperty("loads", {complete: true, state: state})}}>Submit</SynchDisabledButton>
        {/* TODO: Move submit button to a wrapper element? */}

        {/* Canvas Width (original) is separate from styling width, which uses css to rescale the image of the canvas. */}
        {width < height? <IonText color="danger"><p>For best performance, consider using landscape mode.</p></IonText> : <></>}
      </IonCardContent>
    </IonCard>
  );
};

export default EquipmentLoads;